.blog-categories {
    margin: 2% 0;
}

.banner-blog {
    margin: 2% 0;

}

.blog-banner {
    background-image: url("../Imgs/Banner\ Blog.png");
    background-size: 100% 100%;
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--blanc);
    line-height: 40px;

}

.blog-banner p {
    width: 50%;
    margin: 1%;
    text-align: center;
}

/* List blog  */
.blog-posts {
    width: 94%;
    margin: 0 3%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.blog-posts .card-post {
    width: 30%;
    height: auto;
    margin: 4% 0;
    border-radius: 8px;
    background: var(--blanc) !important;
    box-shadow: 0px 10px 30px 0px var(--box-shadow-color);
}

.post-title {
    width: 100%;
}

.img-card-post {
    position: relative;
    width: 100%;
    height: 14rem;
    border-radius: 8px 8px 0 0;
}

.blog-posts .card-post .description-card {
    position: relative;
    padding: 8%;
}

.blog-posts .card-post .description-card p {
    margin: 4% 0;
    color: var(--gris-fumee);
}

.read-more {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

/* Post */

.post-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 4rem;
}

.img-post {
    width: 100%;
    height: 80vh;
    filter: blur(0.5px)
        /* Applique l'effet de flou */

}

.post-title-details {
    position: absolute;
    background-color: rgba(89, 89, 89, 0.246);
    position: absolute;
    color: var(--blanc);
    top: 0vh;
    height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.post-title-details h1 {
    width: 50%;
    text-align: center;
}

.post-content .post-details {
    width: 80%;
    height: auto;
}


.post-content .post-details .description-card {
    padding: 3%;
    position: relative;
    bottom: 5rem;
    text-align: start;
    line-height: 28px;
    font-weight: 500;

    /* background: var(--background-white); */
}

.post-content .post-details img {
    width: 100%;
    height: 22rem;
    border-radius: 4px;
    margin: 2% 0;

}


.similary h1 {
    margin: 6% 0;
}





/* *************************** */

/* *************************** */

/* *************************** */

/* *************************** */

/* *************************** */

/* *************************** */



/* Responsive APP */

/* Responsive Blog App*/
@media screen and (max-width : 768px) {
    /* .blog-banner .top-banner{
        width: 90%;
        margin-top: 10rem;
    }
    .blog-banner .top-banner h1 {
        font-size: 30px;

    } */

    .blog-banner {
        background-image: url("../Imgs/banner\ Blog\ mobile.png");
        background-size: 100% 100%;
        height: 60vh;
    }

    .blog-banner h1 {
        width: 90%;
        text-align: center;
    }

    .blog-banner p {
        width: 90%;

    }

    .blog-posts {
        flex-direction: column;
        align-items: center;
    }

    .blog-posts .card-post {
        width: 90%;
        height: auto;
        margin: 5% 0;
        box-shadow: none;
    }

    .blog-posts .card-post .description-card {
        font-size: 15px;
        text-align: start;
    }

    .post-title-details,
    .img-post {

        height: 40vh;


    }

    .post-title-details h1 {
        width: 90%;

    }

    .post-content .post-details {
        margin-top: 4%;
        width: 80%;
        height: auto;
    }

}