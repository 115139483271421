
/* IN Home Component*/
.count-down-timer-layout{
    background-color: #FEF4EA;

    position: relative;
    bottom: 75px;
    /* z-index: 210; */

}

.count-down-timer-layout-subscription{
    width: 100%;
    position: relative;
    top: 85px;
}
.countdown-container {
    background-color: #FEF4EA;
    color: var(--orange-brule);
    text-align: center;
    /* padding: 50px 10px; */
    /* font-family: Arial, sans-serif; */
    height: 120px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* box-shadow: -2px -1px 20px 0px var(--noir); */
}

.timer-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.timer-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 60px;
    border: 2px solid var(--orange-brule);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.time-value {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 5px;
}

.label {
    font-size: 0.7rem;
    text-transform: uppercase;
}

.colon {
    font-size: 2rem;
    font-weight: bold;
    margin: 0 5px;
}

.offer-text {
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 20px;
}

@media screen and (max-width: 480px) {

    .count-down-timer-layout-product{
        bottom: -100px !important;
    }
    .count-down-timer-layout{
        position: relative;
        bottom: 110px;
        /* z-index: 210; */
    
    }
    .countdown-container {
        padding: 10px;
        height: 100px;
        padding: 2%;
    }
    .timer-wrapper {
        width: 100%;
        padding: 0;
        margin: 0;
    }
    
    .timer-box {
        width: 60px;
        height: 60px;

    }
    .time-value {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 5px;
    }
    
    .label {
        font-size: 0.6rem;
    }
    .offer-text {
        font-size: 0.9rem;
        font-weight: bold;
        margin-top: 20px;
    }
}