/* Colors variables   */

/* variables.css */
:root {
    --bleu-sarcelle: #00ACC1;
    --orange-brule: #f4922a;
    --bleu-ciel: #00ABF3;
    --noir: #000000;
    --noir-charbon: #212429;
    --gris-fumee: #495057;
    --gris-argente: #ACB5BD;
    --gris-clair: #DDE2E5;
    --gris-tres-clair: #F8F9FA;
    --blanc: #FFFFFF;
    --background-white: #F4F7FB;
    --box-shadow-color: rgba(62, 19, 77, 0.47) ;
}