/* Sign in  */
*{
    padding: 0;
    margin: 0;
}

.layout-signin{
    width: 98%;
    height: auto;
    /* padding: 2% 0; */
    /* background: var(--background-white) ; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2%;

}

.layout-signin .logo{
    position: relative;
    display: flex;
    align-items: center;
    width: 55%;
    margin: 2% 0 0% -4%;
}
.layout-signin .logo img{
    width: 50%;
}

.layout-signin .form-signin{
    width: 80%;
    display: inline-flex;
    margin: 3% ;
    padding: 2%;
    align-items: flex-start;
    gap: 68px;

    border-radius: 4px;
    background: var(--blanc);
    box-shadow: 0px 10px 30px 0px rgba(62, 19, 77, 0.25);
}

.form-signin form {
    width: 100%;
    height: 410px;
    font-family: Montserrat;


    display: flex;
    flex-direction: column;
    justify-content: center;
}


.form-signin form div input {
    width: 98%;
    height: 44px;
    flex-shrink: 0;
    margin: 4px 0;

    border-radius: 4px;
    border: 1px solid var(--bleu-ciel);
    background: var(--blanc);
}

.container-right-signin{
    width: 100%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-title-signin {

    width: 98%;
    height: 54px;
    flex-shrink: 0;
    text-align: center;
    display: flex;
    align-items: center;
    margin: 0 0 10px 0; 
    

    border-radius: 4px;
    background: var(--blanc);
    
    font-family: Montserrat;
    /* font-size: 14px; */
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    box-shadow: 0px 0px 5px rgba(62, 19, 77, 0.47);
}

.title-google{
    display: flex;
    justify-content: space-around;
}
.card-title-signin img{
    width: 50px;
}


.card-title-signin h3 {
    padding: 4%;
    font-size: 15px;

}
.btn-signup{
    width: 98%;
    height: 54px;


    border-radius: 4px;
    font-size: 18px;
    font-weight: 750;
    font-family: Montserrat;
    border: none;
    text-decoration: none;
    color: var(--blanc);
    background: var(--orange-brule);
}
.btn-form-connection{
    width: 100%;
    height: 54px;
    flex-shrink: 0;
    margin: 4px 0;
    display:flex ;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;

    border-radius: 4px;
    font-size: 18px;
    font-weight: 750;
    font-family: Montserrat;
    border: none;
    text-decoration: none;
    color: var(--blanc);
    background: var(--orange-brule);
}

.btn-form-connection .btn-signup{
    text-decoration: none;
    color: var(--blanc);
}

.container-right-signin img{
    width: 50%;
    margin: 3% 8%;
    margin: 2% 23%;
}
.container-right-signin .sign-in{
    background: var(--bleu-sarcelle);
    box-shadow: 0px 10px 30px 0px rgba(62, 19, 77, 0.47);

}
.card-title-signup{
    width: 100%;
    height: 410px;
    flex-shrink: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    text-align: start;
    justify-content: center;
    align-items: center;
    color: var(--noir);

    border-radius: 4px;
    
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

}
.card-title-signup h3{
margin: 0 35%;
}
#new-user{
    color: var(--blanc);
    text-align: center;
}
.card-title-signup .sign-up{
    color: var(--noir);
    text-align: center;
}

.ou{
    display: flex;

}
.ou hr{
    width: 46.5%;
    height: 2px;
    margin: 9px 0;
    background: var(--noir);

}
/* End sign in */





/* Sign Out  */
.btn-sign-out{
    width: 120px;
    height: 34px;
    padding: 5px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;

    border-radius: 4px;
    border: none;
    color: var(--blanc);
    background: var(--orange-brule);

    color: var(--blanc);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}


/* Sign Up */

.full-name{
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
}
.full-name div{
    width: 100%;
}

/* Reset Pass word */

.form-req-reset-password{
    width: 50%;
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 7rem;
}
.form-req-reset-password form div{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 4% 0;

}

.form-req-reset-password form div input{
    width: 100%;
    height: 3rem;
    padding: 0 4%;
    font-size: 18px;
}

#btn-resetpw{
    font-weight: 600 !important;
    padding: 0 5%;
}



/* *************************** */

/* *************************** */

/* *************************** */

/* *************************** */

/* *************************** */

/* *************************** */



/* Responsive APP */

/* Responsive Connection*/
@media screen and (min-width : 768px) and (max-width : 1023px) {

    .layout-signin .logo img{
        width: 40%;
    }
}

/* @media screen and (min-width : 320px) and (max-width : 767px) { */
@media screen  and (max-width : 767px) {
        .layout-signin .form-signin{
        display: flex ;
        flex-direction: column;         
    }
    .form-signin form{
        align-items: center;
        height: auto;
        font-size: 15px;
    }
    .form-signin form div  {
        width: 80%;

    }


    .container-right-signin .sign-in{
        width: 80%;
        height: 10rem;
        margin-top: -15%;
        margin-bottom: 8%;

    }
    .btn-form-connection{
        width: 50%;
        font-size: 15px;
        font-weight: 600;
    }   

    .full-name{
        flex-direction: column;
    }
    .full-name div input{
        width: 122% !important;
    }
    .container-right-description{
        display: none;
    }


    .form-req-reset-password{
        width: 100%;
        height: auto;


    }
    #btn-resetpw{
        width: 90%;
    }
    .form-req-reset-password form div input{
        width: 90%;
        background-color: aqua !important;

    }

}

@media screen and (min-width : 0px) and (max-width : 319px) {

}