/* Home */
* {
    font-family: Montserrat;

}

.home {
    background: var(--background-white);

}

/* BAnner */

/*
Homme Banners
*/



.netflix-banner {
    background-image: url("../../Assets/Imgs/netflix-banner.png");
    background-size: 100% 100%;
    width: 100%;
    height: 92vh;
    display: flex;
    justify-content: space-around;

}

.netflix-banner .netflix-banner-left {
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: 58%;
    position: relative;
    bottom: 6rem;
    margin-left: 15px;
}


.netflix-banner .netflix-banner-left h1 {
    color: red;
}

.netflix-banner .netflix-banner-left h2 {
    color: var(--blanc);
    font-size: 3rem;
}

.netflix-banner .netflix-banner-right {
    display: flex;
    width: 38%;
    position: relative;
    top: 6rem;

}

.netflix-banner .netflix-banner-right img {
    width: 250px;
    height: 350px;

}


/* Disney */
.disney-plus-banner {
    background-image: url("../../Assets/Imgs/Variant4.png");
    background-size: 100% 100%;
    width: 100%;
    height: 92vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.disney-plus-banner .title-disney-plus {
    color: var(--blanc);
    font-size: 3.5rem;
}

.disney-plus-banner .description-disney-plus {
    display: flex;
    flex-direction: row;
    padding: 1% 2%;
    background-color: var(--gris-clair);

}

.disney-plus-banner .title-disney-plus {
    text-align: center;

}



.disney-plus-banner .description-disney-plus h3 {
    font-size: 25px;
    color: #083C7D;

}

/* Spotify */
.spotify-banner {
    background-image: url("../../Assets/Imgs/spotify-banner.png");
    background-size: 100% 100%;
    width: 100%;
    height: 92vh;
    display: flex;
    align-items: end;
}

.spotify-banner h1 {
    color: var(--blanc);
    font-size: 3rem;
    position: relative;
    bottom: 6rem;
    margin-left: 2rem;
}

/* Ce style là devrait etre sur la partie Home.css ùmais vu les deux banner sont pratiquement les meme je le mets ici  */
.layout-banner-right-home {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: end;
}

.layout-banner-right-home img {
    width: 90%;
    height: 100%;
}

.description-banner {
    color: var(--bleu-ciel);
    text-align: left;
    /* margin-left: -50px; */
    position: relative;
    top: 17px;
}

.see-all-products {
    background-color: var(--orange-brule);
    padding: 15px 20px;
    color: var(--blanc);
    text-decoration: none;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
    position: relative;
    top: 20px;
}


.img-banner-home {
    width: 500px;
    height: 300px;
    transition: opacity 1s ease-in-out;
    /* Transition douce lors du changement */
    opacity: 0;
    margin-left: 60px;
    position: relative;
    top: 40px;
}

.img-banner-home.slide-in {
    opacity: 1;
    /* Affiche l'image en douceur */

}

@media screen and (min-width : 1512px) {
    .img-banner-home {
        height: 150px;
    }

    .layout-banner-left {
        min-height: 70%;
    }
}



/* sub share */
.sub-share {
    width: 90%;
    margin: 0 5%;
    display: flex;
    justify-content: space-between;
    height: 80vh;

}

.sub-share-elements {
    width: 49%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: var(--blanc);

}

.sub {
    background-color: var(--bleu-ciel);
}

.shares {
    background-color: var(--orange-brule);
}

.sub-share .sub-share-elements .sub-text {
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.sub-share .sub-share-elements .sub-text .sub-para {
    display: flex;
    align-items: center;
}

.sub-share-elements .sub-text .check-mark {
    width: 32px;
    height: 32px;
    margin-right: 10px;
}

.btn-sub-share {
    font-size: 17px;
    font-weight: 600;
    color: var(--noir);
    background-color: var(--blanc);
    text-decoration: none;
    cursor: pointer;
    width: 70%;
    padding: 2%;
    border-radius: 4px;
}

.sub-text .btn-sub-share .go-arrow {
    font-size: 20px;
    margin-left: 5%;
}


/* End sub share */


/* How-it-works */
.How-it-works {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.How-it-works .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    bottom: 10px;
    font-size: 25px;
    justify-content: center;
}

.How-it-works .title h1 {
    color: var(--bleu-ciel);
}



.How-it-works .choice-option {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--gris-clair);
    height: 5rem;
    border-radius: 40px;
    display: flex;
}


.btn-choice-option {
    background: var(--gris-clair);
    color: gray;
    font-size: 16px;
    width: 46%;
    font-weight: 700;
}

#btn-choice-option-active {

    background: var(--orange-brule);
    color: var(--blanc);
    padding: 2%;
    margin: 0 2%;
    border-radius: 70px;
}

#how {
    color: var(--orange-brule);
}

/* End How it work-us */


/* How To Work 2 */


.subscription-container2 {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subscription-container2 .sub_share_title h1,
p {
    margin-bottom: 15px;
}

.btn-container-how-work {
    margin-top: 10px;
    display: flex;
    align-items: center;
    width: 50%;
}

.btn-container-how-work .btn {
    color: var(--bleu-ciel);
    background-color: var(--blanc);
    border: solid var(--bleu-ciel);
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 10px;
}

.btn-container-how-work .btn_active {
    background-color: var(--bleu-ciel);
    color: var(--blanc);
}

.steps-container {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}


.step-card {
    position: relative;
    width: 20%;
    height: 250px;
    border-radius: 8px;
    padding: 20px;
    margin: 10px;
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-1 {
    background-color: #e7e3f7;
}

.card-2 {
    background-color: #fdf1d4;
}

.card-3 {
    background-color: #f8dcd7;
}

.card-4 {
    background-color: #d9f0f2;
}

.circle {
    /* position: absolute; */
    /* top: 10px; */
    /* right: 10px; */
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    background-color: var(--blanc);

}

.card-1 .circle {
    border: solid #c6b7f3;
    color: #c6b7f3;
}

.card-2 .circle {
    color: #fbeab2;
    border: solid #fbeab2;
}

.card-3 .circle {
    color: #f5b0aa;
    border: solid #f5b0aa;
}

.card-4 .circle {
    color: #d9f0f2;
    border: solid #d9f0f2;
}


/* End */
/* ******************** */
/* ******************** */

/* about-us */

.about-us {
    display: flex;
    flex-direction: column;
    text-align: center;
}


.home-trust h2 {
    color: var(--bleu-ciel);
    margin: 2%;
    font-size: 45px;
    background-image: url("../Imgs/icons//icon-fill.svg");
    background-repeat: no-repeat;
    background-size: 18%;
    background-position: 40% 3rem;

}

.talk-about-us {
    width: 100%;
    /* margin: 0 5%; */
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    background-color: var(--blanc);
    box-shadow: 0px 1px 10px 0px #f1f0f0;
}

.talk-about-us img {
    width: 25%;
}


.about-us-description {
    height: auto;
    padding: 40px 0;
    margin: 10% 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 20px;
    color: var(--noir);
    background-color: #FFF5F2;
}

.about-us-description img {
    width: 35%;
}

.about-us-description .about-us-description-right {
    width: 40%;
    display: flex;
    flex-direction: column;
    text-align: justify;
    background-image: url("../Imgs/icons/icon-fill.svg");
    background-repeat: no-repeat;
    background-position: 10px 40px;
    margin-right: 3%;
}

/* .about-us-description .about-us-description-right h1 {
    width: 89%;
    background-color: #c6b7f3 !important;
} */

.about-us-description .about-us-description-right p {
    margin: 4% 0;
}


.about-us .custom-card {
    width: 92%;
    padding: 4%;
    display: flex;
    justify-content: space-between;
}

.about-us .custom-card .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 26%;
    height: 20rem;
    padding: 2%;
    border-radius: 4px;
    color: var(--noir-charbon);
    text-align: center;
    background: #e6f7fe;
}

.about-us .custom-card #card2 {


    background: var(--bleu-ciel);
}




.about-us .custom-card .card img {
    width: 50px;
}

.about-us .custom-card .card h3 {
    text-align: center;
    margin: 1%;
    width: 100% !important;
}

.about-us .custom-card .card .description-card p {
    text-align: start;
    padding: 10px 0;

}

.about-us .about-us-img {
    width: 92%;
    padding: 4%;

}





/* *************************** */

/* *************************** */

/* *************************** */

/* *************************** */

/* *************************** */

/* *************************** */



/* Responsive APP */

/* Responsive Home*/


/* Responsive Banner */

@media screen and (max-width : 950px) {

    .layout-banner-left-description {
        position: relative;
        top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }


    @media screen and (max-width : 500px) {


        .banner .layout-banner {
            width: 100%;
        }


        .banner-home-btn {
            width: 50%;
        }

        .img-banner-home {
            margin-top: 50px;
            margin-left: 0px;
            width: 500px;
            width: 400px;
        }

        .spotify-banner {
            background-size: 100% 83%;
            background-repeat: no-repeat;
            align-items: center;

        }

        .spotify-banner h1 {
            font-size: 2.7rem;
            bottom: 4rem;
            margin-left: 2rem;
        }

        .disney-plus-banner {
            background-image: url("../../Assets/Imgs/Variant4.png");
            background-size: 100% 83%;
            background-repeat: no-repeat;

        }

        .disney-plus-banner .title-disney-plus {
            font-size: 3rem;
            position: relative;
            bottom: 4rem;

        }

        .disney-plus-banner .description-disney-plus {
            position: relative;
            bottom: 1rem;

        }


        .netflix-banner {
            background-size: 100% 83%;
            background-repeat: no-repeat;

        }

        .netflix-banner .netflix-banner-left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            position: relative;
            bottom: 8rem;
            margin-left: 20px;
        }

        .netflix-banner .netflix-banner-left h1 {
            font-size: 3rem !important;
            position: relative;
            top: 2rem;
        }

        .netflix-banner .netflix-banner-left h2 {
            position: relative;
            top: 4rem;
        }

        .netflix-banner .netflix-banner-right {
            display: none;

        }

        .netflix-banner .netflix-banner-left img {
            position: relative;
            top: 8rem;
        }

    }

    @media screen and (max-width : 389px) {

        .banner .layout-banner {
            width: 100%;
        }

        /* 
        .banner .layout-banner .top-banner h1 {
            font-size: 25px;
            line-height: 2.5rem;

        } */
        .banner-home {
            top: 12% !important;
        }

    }


}

/* End Responsive Banner */


/* ******************************************** */
/* ******************************************** */

/* Responsive  Share and Subscription  */
@media screen and (max-width : 950px) {
    @media screen and (max-height : 700px) {
        .sub-share {
            position: relative;
            top: -10rem !important;
        }

        .more-freq {
            margin: 20% 0;
        }
    }

    .sub-share {
        flex-direction: column;
        height: 60rem;
        position: relative;
        /* top : -10rem; */
    }

    .sub-share-elements {
        width: 100%;
        height: 100%;
        font-size: 16px;
        margin: 4% 0;
    }

    .sub-share .sub-share-elements .sub-text h2 {
        font-size: 20px;
        font-weight: 800;
    }

}

@media screen and (max-width : 500px) {

    .btn-sub-share {
        width: 100%;
        font-size: 16px;
        font-weight: 700;

    }
}

@media screen and (max-width : 389px) {
    .sub-share {
        top: -6rem !important;
    }

    .btn-sub-share {
        font-size: 15px;
        font-weight: 800;

    }
}

/* End Responsive Custom Share and Subscription  */

/* ******************************************** */
/* ******************************************** */

/* Responsive How it Work  */

@media screen and (max-width : 950px) {
    @media screen and (max-height : 500px) {
        .How-it-works .title {
            margin-top: 5% !important;

        }
    }

    @media screen and (max-height : 500px) and (max-width : 550px) {
        /* .How-it-works .title {
            margin-top: 30% !important;

        } */
    }

    .How-it-works {
        position: relative;
        bottom: 1rem;
    }

    .How-it-works .title {

        bottom: 10px;
        font-size: 20px;
        line-height: 4rem;
    }

    .btn-container-how-work {
        margin-top: 10px;
        height: 100px;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        width: 80%;
    }

    .steps-container {
        width: 100%;
        flex-direction: column;

    }

    .step-card {
        width: 80%;
    }

}

@media screen and (max-width : 550px) {

    .How-it-works .title {
        font-size: 15px;
    }

    .How-it-works .choice-option {
        width: 80%;
        margin: 8% 0 10% 0;
    }

    .btn-choice-option {

        font-size: 16px;
        width: 46%;
        font-weight: 700;
    }
}

@media screen and (max-width : 390px) {

    .How-it-works .title {

        font-size: 12px;
    }
}



/* End Responsive How it Work  */
/* End Responsive Banner */



/* Responsive How it Work  */


/* Responsive About us */
@media screen and (max-width : 800px) {

    .about-us {
        position: relative;
        bottom: 6rem;
    }

    .home-trust h2 {
        font-size: 35px;
        margin: 2% 0;

    }

    .talk-about-us {
        background: none;
        margin: 2% 0;
    }

    .talk-about-us img {
        width: 35%;
    }

    .about-us-description {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        font-size: 15px;
    }

    .about-us-description img {
        width: 80%;
        margin-right: 2%;
    }

    .about-us-description .about-us-description-right {
        width: 80%;
        background: none;
        background-position: 100px 45px;

    }

    .about-us .custom-card {
        /* width: 92%;
        padding: 4%; */
        flex-direction: column;
        align-items: center;
    }

    .about-us .custom-card .card {
        width: 50%;
        height: auto;
        margin: 2%;
        box-shadow: none;
    }

    .about-us .custom-card .card img {
        width: auto;
        height: auto;
    }

    .about-us .custom-card .card h3 {
        margin: 5%;
        width: 40%;

    }




}

@media screen and (max-width : 430px) {
    .home-trust h2 {
        font-size: 25px;
    }

    .about-us-description {
        font-size: 15px;
    }

    .about-us .custom-card .card {
        width: 90%;
    }

}


/* Responsive About us */