
#politique , #condition{
position: relative;
top: 110px;
margin-bottom: 210px;
}

.titre::after {
    position: relative;
    content: '';
    display: block;
    width: 380px;
    height: 1px;
    opacity: .2;
    margin-top: 22px;
    margin-bottom: 29.5px;
    left: 35%;
    background-color: #F4932A;
}

.titre h1 {
    text-align: center;
    /* font-size: 14px; */
}

.contenu {
    position: relative;
    padding: 0 100px;
    margin-bottom: 42px;
}

.contenu:last-child {
    margin-bottom: 90px;
}

.contenu .barre {
    position: absolute;
    height: 26.67px;
    width: 2px;
    background-color: #F4932A;
}

.contenu .sous-titre {
    margin-bottom: 30px;
}

.contenu .sous-titre>h2,
.contenu-text>p {
    font-size: 25px;
}

.contenu .sous-titre>h2 {
    padding-left: 15px;
    padding-top: 6.5px;
}

.contenu .contenu-text>p {
    font-weight: 550;
    padding-left: 5px;
}

.contenu .contenu-text>h3 {
    padding-left: 5px;
    font-size: 15px;
}

.contenu .contenu-text span {
    color: #F4932A;
}

.contenu .contenu-text .gras {
    font-weight: bold;
    color: #000;
}

.contenu .contenu-text a {
    text-decoration: none;
    word-wrap: break-word;
}
.date-et-text{
    padding: 0 100px;
    margin-bottom: 42px;
}
.date-et-text p, .contenu .contenu-text>p{
    font-size: 15px;
    font-weight: 550;
    padding-left: 5px;
    text-align: justify;
}

.last-update{
    color: #F4932A;
}


@media only screen and (max-width : 767px){
    .titre h1{
        text-align: left;
        padding-left: 38px;
    }
    .titre::after{
        width: 70vw;
        left: 45px;
    }
    .contenu{
        padding: 0 36px;
    }
    .contenu .contenu-text>p{
        padding-left: 4px;
        line-height: 1.6;
    }
    .date-et-text, .contenu{
        padding: 0 36px;
    }

    .date-et-text p,.contenu .contenu-text>p{
        padding-left: 4px;
        line-height: 1.6;
    }
}