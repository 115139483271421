/* Compte */

/* Account Style */
.account {}

.profile {
    position: relative;
    top: 100px;
    display: flex;
    justify-content: space-between;
}

.content-profile {
    width: 80%;
}

/* End  */
.compte-layout {
    width: 90%;
    margin: 8% 0;
    display: flex;
    height: auto;
}

.compte-user {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3%;
}

.compte-user div h1 {
    width: 6rem;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--orange-brule);
    color: var(--blanc);
    border-radius: 50%;
}

.compte-layout .more-infos {
    width: 50%;
    padding: 3%;
    line-height: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.more-infos p span {
    font-size: 20px;
    font-weight: 700;
}

/* .footer{
    width: 100% !important;
} */






/* user-subscription */

.user-subscription {
    height: auto;
    font-size: 12px !important;
}



.user-subscription-content {
    width: 90%;
    height: 80px;
    position: relative;
    /* padding-top: 10%; */
    margin: 0 5%;

}

.user-subscription-content .user-subscription-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1% 3%;
    height: 3rem;
}

.order-items {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}

.user-subscription-content .layout-content {

    margin: 1% 0;
    padding: 3%;
    border-radius: 4px;
    background-color: var(--blanc);
    box-shadow: 0px 10px 30px 0px var(--box-shadow-color);

}

.suggestion-subscription {
    width: 26%;

}

.user-subscription-content .layout-content .first-content {
    /* width: 20%; */
    /* display: flex; */
    justify-content: space-around;
}

.user-subscription-content .layout-content .second-content {
    width: 100%;
    margin: 10px 0;
}

.user-subscription-content .layout-content .second-content .second-content-items {
    width: 100%;
    display: flex;
    /* margin-top: 1%; */
    /* background-color: #6c757d; */
    justify-content: space-between;
    white-space: pre-line;
}

.user-subscription-content .layout-content .second-content .second-content-key {
    width: 100%;
    background-color: var(--gris-clair);
    white-space: pre-line;
    margin: 10px 0;
    padding: 5px;
    border-radius: 4px;
}


.three-content {
    width: 100%;
    font-size: 14px;
    /* display: flex;
    justify-content: center; */
}

.three-content img {
    width: 30%;
    margin: 2% 0;
}

.user-subscription-content .layout-content .first-content img {
    width: 95%;
    border-radius: 8px;
    height: auto;
}

.user-subscription-content .layout-content .style-line {
    display: flex;
    align-items: center;
}

.layout-content .style-line .vertical-line {
    border-left: 2px solid #000;
    height: 8rem;
    margin: 20px;
}

.btn-submit .link-submit {
    width: 8rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    text-decoration: none;
    color: var(--blanc);
    font-weight: 700;
    background: var(--orange-brule);
}

.user-subscription-content span {
    font-weight: 600;
    margin: 10px;

}

.request-payment-share {
    background-color: var(--orange-brule);
    padding: 4%;
    border-radius: 4px;
    margin: 2% 0;
    color: var(--blanc);
    font-weight: 700;
}

/* edit user infos */

/* Style général pour le formulaire d'édition */
.edit-user {
    max-width: 90%;
    margin: auto;
    border-radius: 10px;
}

.edit-header {
    margin-bottom: 20px;
}

.edit-header h2 {
    font-size: 24px;
    font-weight: bold;
}

.edit-header p {
    color: #6c757d;
    margin-top: 5px;
}

.edit-user-form {
    display: flex;
    flex-direction: column;
}

.form-section {
    width: 90% !important;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    margin: 5px;
    border: solid 1px var(--gris-clair);
    border-radius: 8px;
}

.form-group {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;


}

.form-section label {
    flex: 1;
    font-weight: bold;
    margin: 5px 0;
}

.input {
    flex: 2;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
}

.btn-group {
    text-align: right;
    margin-top: 20px;
}

.btn-save {
    background-color: var(--orange-brule);
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
}

.btn-save:hover {
    background-color: #f76c1d;
}

/* End edit style */

.status-share {
    width: 8rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    text-decoration: none;
    font-size: 12px;
    color: var(--blanc);
    font-weight: 700;
    background: red;
}

/* ReNawal */

button {
    border: none;
    cursor: pointer;
}


/* Edit Infos */
.edit-user-details {
    height: 20rem;
}

.inputs-form {
    width: 80%;
    flex-direction: column;
    align-items: center;
    height: 100%;


}

.inputs-form div {
    width: 100% !important;

}

.inputs-form input {
    height: 3rem;
    width: 50% !important;
}

/* Wish List */
.delete-product-in-wishList {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.deleteToWishList {
    width: 10% !important;
    height: 20px;
    border: none !important;
    padding: 0 4%;
    cursor: pointer;

}

/* End */
/* Wishlist */

.wish-list-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


/* *************************** */

/* *************************** */

/* *************************** */

/* *************************** */

/* *************************** */

/* *************************** */



/* Responsive APP */

/* Responsive Connection*/
@media screen and (max-width : 768px) {
    .edit-user-details {
        margin-top: 30%;
    }

    .compte-layout {
        flex-direction: column;
        justify-content: center;
    }

    .compte-user {
        width: 100%;
    }

    .compte-layout .more-infos {
        width: 100%;

    }

    .inputs-form input {
        height: 3rem;
        width: 100% !important;
    }

    .profile {
        flex-direction: column;
    }

    .content-profile {
        width: 100%;
    }
}

/* End User Edit */

/* *************************** */
/* *************************** */

@media screen and (max-width : 768px) {

    .user-subscription-content {
        padding-top: 16%;
        height: auto;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .user-subscription-content .user-subscription-title {
        flex-direction: column;
        height: auto;
        font-size: 16px;

    }

    .layout-content .style-line .vertical-line {
        display: none;
    }


    .suggestion-subscription {
        width: 100%;
    
    }
    .user-subscription-content .layout-content {

        align-items: start;
        flex-direction: column;
        height: auto;
        margin: 5%;
        font-size: 14px;

    }

    .user-subscription-content .layout-content .first-content {
        display: flex;
        justify-content: flex-start;
        width: 100%;
    }

    .user-subscription-content .layout-content .second-content {
        width: 98%;

    }

    .user-subscription-content span {

        width: 90%;
        font-size: 18px;
    }

    /* .user-subscription-content .layout-content .second-content div {
        width: 100%;
        flex-direction: column;
        margin: 2%;
    } */

    .three-content {
        width: 100%;
        font-size: 15px;
    }

    .three-content img {
        width: 20%;
    }

}




@media screen and (max-width : 430px) {
    .user-subscription-content {
        padding-top: 26%;

    }

    .user-subscription-content .user-subscription-title h1 {

        font-size: 20px;


    }
}