@import url("./Variables.css");

/* NAvBAr */
.nav-bar-parent {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: fixed;
    width: 100%;
    height: 60px;
    z-index: 200;
    top: 0;
    background: var(--gris-tres-clair);

}

.icon-referral {
    margin-right: 10px;
}

.nav-bar {
    width: 100% !important;
    /* position: fixed; */
    height: 80px;
    border-radius: 4px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* margin-bottom: -10px; */
    /* background: var(--gris-tres-clair); */
    

    box-shadow: 0px 5px 5px #efefef;

}


.nav-bar-responsive {
    width: 10%;

}

.nav-bar .logo {
    position: relative;
    right: 15%;
    width: 120px;
    height: 5.8rem;
    display: block;
    /* margin: 1%; */
}

.logo-mobile {
    display: none;
}

.nav-bar .connection-link {
    width: 25%;
    display: flex;
    margin: 0 2%;
    justify-content: space-between;

}

.link-nav-bar {
    color: var(--noir);
    text-decoration: none;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
}

.nav-cart {
    position: relative;
    right: 20px;
}


/* Style of underline of active element in the navbar */

.link-nav-bar.active {
    position: relative;
}

.link-nav-bar.active::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -4px;
    width: 60%;
    height: 4px;
    border-radius: 10px;
    background-color: var(--orange-brule);

}

.alert-netflix{
    background-color: #222;
    color: white;
    width: 100%;
    /* z-index: 200; */
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    }

/* Nav bar mobile */

.navbar-mobile {
    display: none;
}

.general-btn-link {
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
    color: var(--blanc);

}

.btn-connection {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 1.2rem;
    color: var(--noir);
    text-decoration: none;
    width: 120px;
    background: var(--orange-brule);
    padding: 10px;
    border-radius: 8px;
    font-weight: bold;
    margin-left: 12px;

}

.btn-connection p {
    margin: 0 !important;
}

.btn-main-layout {
    background: var(--bleu-ciel);
    /* width: 170px !important; */
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 5px 0; */
    text-align: center;
    padding: 2px;
}

.nav-bar .connection-link .connection {
    width: 150px;
    height: 35px;
    position: relative;
    bottom: 5px;
}

.user-compte {
    position: relative;
    display: flex;
    min-width: 23%;
    justify-content: space-between;
    align-items: center;
    color: var(--blanc);

}

.refer-a-friend-link {
    text-decoration: none;
    display: flex;
    width: 100px;
    height: 1.2rem;
    padding: 10px 15px;
    background-color: var(--bleu-ciel);
    color: var(--blanc);
    border-radius: 8px;
    font-weight: bold;

}

.cart-referral {
    display: flex;
    align-items: center;
    gap: 15px;
}

#user-name {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px 2px;
    border-radius: 8px;
    color: var(--noir);
    background: var(--orange-brule);
    width: 100px;
    height: 1.2rem;
    text-decoration: none;

}

#user-first-name {
    color: var(--noir);
    background-color: var(--blanc);
    border-radius: 100px;
    padding: 3px;
    width: 25px;
    height: 25px;
}

.dropdown-content {
    display: none;
    position: absolute;
    background: var(--bleu-ciel);
    width: 148px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 1px;
    z-index: 1;
    border-radius: 0 0 4px 4px;
}

.dropdown-item {
    text-decoration: none;
    font-weight: 550;
    font-size: 15px;
    color: var(--blanc);
    margin: 2%;

}

#length-items-cart {
    background-color: var(--gris-clair);
    color: var(--orange-brule);
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
    left: 18px;
    top: 6px;

}



/* 
.dropdown:hover .dropdown-content {
    display: flex;
    flex-direction: column;
    left: 0;
    top: 1.7rem;
} */


/* Responsive */

.sous-menu {
    display: none;
}




/* Custom Layout list */
.list-elements-wrapper {
    display: flex;

}

.list-elements-details {
    position: relative;
    width: 100%;
    margin: 3% 0;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;


}

.backGroundOrange {
    background-color: #FEF4EA;
}

.lineBackGroundGray {
    background-color: #eaeff0 !important;
}

.items-subs {
    display: flex;
    width: 94%;
    overflow-x: scroll;
    scrollbar-width: none;

}

.categories-product-elements-title {
    width: 92%;
    display: flex;
    justify-content: space-between;
    align-items: center;

}


.title-category {
    display: flex;
    width: auto;
    height: 70px;
    justify-content: space-between;
    align-items: center;
}

.title-category h1 {
    font-size: 20px;
}

.title-category img {
    width: 20px;
    margin: 20px;
}

.horizontal-line {
    /* min-width: 68%; */
    flex-grow: 1;
    background-color: var(--orange-brule);
    height: 2px;
    border: none;

}

.view-all {
    text-decoration: none;
    background-color: var(--orange-brule);
    width: 10%;
    height: 40px;
    margin-left: 20px;
    border-radius: 8px;
    color: var(--blanc);
    /* font-size: 20px; */
    display: flex;
    justify-content: center;
    align-items: center;

}

/* Id education  */
.categories-product #logiciel {
    /* padding-bottom: 10rem; */

}




.categories-product-elements.active {
    padding-top: 4%;
}

/* .custom-list-details:nth-child(n+6) {
    display: none; /* Masquer tous les éléments après le 5ème 
  } */

.custom-list-details .custom-list-details-card {
    width: 210px;
    height: 300px;
    margin: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    border-radius: 4px;
    background: white;
    border: solid #eaeff0;

}

.status-products {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 100px;
    position: relative;
    top: -40%;
    left: 20%;

    background-color: var(--gris-clair);
    border-radius: 30px;

}

.status-popular {
    width: 80px;
    left: 24%;

}

.status-products .flash {
    border: none;
    width: 14px;
    margin-bottom: 8px;
    padding: 0 8px;
}

.text-in-promo {
    margin-top: 13px;
    margin-right: 4px;
    width: 80px;
    font-size: 13px;
    text-align: center !important;
}

.custom-price-inPromo {
    display: flex;
    align-items: center;
    gap: 5px;
}

.price-promo {
    /* color: var(--orange-brule) ; */
    /* color: black; */
    color: var(--bleu-ciel) !important;
    font-weight: 700;
}

/* .custom-price-inPromo span{
    font-weight: 700;
} */
.line-separate {
    width: 10px;
    height: 2px;
    background-color: var(--noir);
    border: var(--noir);
    margin-left: 4px;
}

.custom-list-details .custom-list-details-card .card-img {
    width: 90%;
    height: 45%;
    display: flex;
    justify-content: center;
    align-items: center;


}

.custom-list-details div img {
    width: 98%;
    max-height: 90%;
    border-radius: 2px;
    margin-top: 8%;
    border-radius: 4px;
    border: solid #eaeff0;

}

.custom-list-details .card-details-elements {
    height: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 2% 0;
    width: 90%;
}

.custom-list-details-card p,
h4 {
    width: 98%;
    text-align: start;
    font-size: 13px;

}

.custom-list-details-card span {

    font-size: 14px;
    color: black;


}

.custom-list-details-card #type-sub {
    color: var(--orange-brule);

}


.custom-list-details-card #btn-subscription {
    height: 2.3rem !important;
    width: 98%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2%;
    background-color: var(--orange-brule);
    color: #7C7C7C;
    font-weight: 600;

}

#btn-subscription .link-subscription {
    text-decoration: none;
    color: var(--blanc);
}



.pagination {
    width: 4%;
    margin: 1%;
    position: relative;
}

.pagination button:hover {
    background-color: var(--gris-clair);
    color: var(--noir);
}

.product-rupture {
    background-color: red;
    padding: 8px;
    width: 90%;
    border-radius: 4px;
    color: var(--blanc);
    font-weight: bold;
}

.price-product {
    width: 100%;
    display: flex;
}

/* *********************************** */
/* List  && CustomListCard */
.list-home {
    position: relative;
}


.list-carte {
    width: 12rem;
    height: 15rem;
    margin: 12px;
    display: flex;
    justify-content: center;
    text-align: center;
    background: var(--gris-tres-clair);
    box-shadow: 0px 10px 15px 0px var(--noir);
    position: relative;
    /* Ajout pour positionner les éléments enfants */
}

.list-carte .card-carte {
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.list-carte .card-carte img {
    position: relative;
    width: 90%;
    height: 9rem;
    margin-top: 10px;
}

.carte-details {
    position: relative;
    /* Position absolue pour les superposer */
    opacity: 1;
    /* Affichage initial */
    transition: opacity 0.3s ease;
    /* Transition pour un effet de fondu enchaîné */
}

.btn-carte {
    position: relative;
    bottom: 22%;
    box-shadow: 2px 8px 10px var(--noir);
    background-color: var(--orange-brule);
    width: 80%;
    padding: 0.45rem;
    opacity: 0;
    /* Initialement masqué */
    transition: opacity 0.3s ease;
    /* Transition pour un effet de fondu enchaîné */
}

/* Pseudo-classe hover pour afficher/masquer les éléments */
.list-carte:hover .carte-details {
    opacity: 0;
}

.list-carte:hover .btn-carte {
    opacity: 1;
}

.link-subscription-carte {
    text-decoration: none;
    color: var(--blanc);
    font-weight: 800;
}

/* End list */

/* ************************ */
/* ************************ */







/* *************************** */
/* *************************** */



.footer {
    background-color: #112E45;
    position: relative;
    width: 100%;
    margin-top: 8%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.footer .social {
    position: relative;
    top: -17px;
    display: flex;
    justify-content: center;
}

.footer a {
    text-decoration: none;
}

.footer .social a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: #F4932A;
    color: #fff;
    border-radius: 50%;
    margin: 0 5px;

}

.footer .menu {
    text-align: center;
}

.footer .menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer .menu ul a {
    font-size: 16px;
    color: #fff;
    line-height: 2.5;
    font-weight: 300;
}

.footer .menu ul a.active {
    font-size: 18px;
    color: #fff;
    font-weight: bold;
}

.footer .logo {
    display: flex;
    justify-content: center;
    position: relative;
    left: 10px;
}

.footer .logo img {
    width: 10rem;
    height: auto;
}


.footer .copyright {
    /* text-align: center; */
    display: flex;
    align-items: center;
    margin-left: 10px;
    gap: 5px;

}

.footer .copyright {
    color: #fff;
    font-size: 15px;
    padding-bottom: 15px;
    font-weight: 300;

}

.footer .made {
    display: flex;
    justify-content: center;
    align-items: center;
}

.copyright img {
    width: 20px;
    margin: 4px;
}


/* Style of sidbar user */

.sidebar-user {
    width: 20%;
    /* position: fixed; */
    height: auto;
    background-color: var(--gris-clair);
    padding: 20px;
    border-radius: 10px;

}

.profile-section {
    text-align: center;
    height: auto;
    background-color: var(--blanc);
    border-radius: 10px;
    padding: 20px 0;
}

/* .background-profile {
    background: var(--bleu-ciel);
    position: relative;
    width: 100%;
    height: 70px;
    z-index: 98;
    top: -100px;
    border-radius: 10px 10px 0 0;

} */
/* 
.profile-pic {
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 4px solid var(--blanc);
    z-index: 99;

} */

.user-name {
    /* margin: 10px 0; */
    font-size: 18px;
    position: relative;

}

.points-in-sidebar {
    display: flex;
    position: relative;
    justify-content: center;
    gap: 10px;

}

.user-pts {
    color: var(--bleu-ciel);
}

.menu-section {
    margin-top: 20px;
}

.menu-section h4 {
    font-size: 14px;
    margin-bottom: 10px;
    color: #6c757d;
    font-weight: bold;
}

.menu-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.menu-list li {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
    font-size: 16px;
    cursor: pointer;
    white-space: nowrap;
    /* Empêche le retour à la ligne */

}


.menu-list li:hover {
    width: 105%;
    background-color: #e9ecef;
    border-radius: 5px;
}

.menu-list li span {
    /* margin: 40px; */
    margin-left: 10px;
    color: #333;


}

/* Icon Circle */
.icon-circle {
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    text-decoration: none;
}

.menu-icon {
    color: var(--bleu-ciel);
    font-size: 20px;
    text-align: center;
    background-color: #e9f0ff;
    padding: 12px;
    border-radius: 50%;


}

#delete-account {
    color: #dc3545;
}

.logout-section {
    margin-top: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.logout-section span {
    margin-left: 10px;
    font-size: 16px;
}

.logout-section .icon-circle {
    background-color: #e9f0ff;
    border-radius: 4px;
    padding: 5px;
}

/* End Style sidebar */

/* *************************** */

/* *************************** */

/* *************************** */

/* *************************** */

/* *************************** */

/* *************************** */

/* Responsive APP */

/* Nav bar */
@media screen and (max-width : 1150px) {
    .nav-bar {
        /* justify-content: space-between   ; */
        /* width: 98%; */
        margin: 0;
        box-shadow: none;
    }

    .nav-bar .logo {
        display: none;
    }

    .logo-mobile {
        width: 110px;
        height: auto;
        /* width: 300px;
        height: 160px; */
        display: block;
    }

    .nav-bar .nav-bar-responsive {
        width: 99%;
        /* padding: 1%; */
        display: flex;
        justify-content: space-between;

    }

    .sous-menu {
        display: flex;
        width: 50%;
        justify-content: space-between;
        align-items: center;

    }

    .sous-menu-navigation {
        background-color: var(--orange-brule);
        padding: 8px;
        border-radius: 8px;
        font-weight: bolder;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .btn-close-dropdown-menu{
        font-weight: bold;
        color: var(--orange-brule);
    }

/* Alert netflix */
.alert-netflix{
    font-size: 12px;
    }

    #link-cart {
        /* margin-right: 10px; */
        margin-top: -15px;
    }

    .link-nav-bar.active::after {
        display: none;

    }

    .user-compte {
        display: none;
    }

    .nav-bar .connection-link {
        position: absolute;
        top: 70%;
        right: -100%;
        display: flex;
        flex-direction: column;
        height: 10rem;
        width: 40%;
        padding: 2%;
        background-color: rgba(0, 0, 0, 0.5);
        margin-left: 10px;
    }

    .link-nav-bar {
        color: var(--blanc);
    }

    .link-nav-bar.active::after {

        left: 30%;
        width: 30%;

    }

    .icon-connection-mobile {
        width: 29px;
        height: 29px;
    }

    .icon-signin-mobile {
        color: var(--bleu-ciel);
    }

    /* NAvbar mobile  */

    .navbar-mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #c0e5f6;
        width: 100% !important;
        position: fixed;
        top: 80px;
        z-index: 100;
        padding: 25px 0;

        border-radius: 0 0 50px 50px;
        box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.158);

    }

    .navbar-mobile-content{
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        margin: 10px 0;
    }
    .navbar-mobile-link{
        color: var(--noir);
        text-decoration: none;
        font-size: 25px;
        font-weight: bold;
        display: flex;
        gap: 15px;
    }
    .btn-login{
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 1.2rem;
        color: var(--noir);
        text-decoration: none;
        width: auto;
        background: var(--orange-brule);
        padding: 15px;
        border-radius: 8px;
        font-weight: bold;
        font-size: 25px;
        gap: 10px;
    }

}

@media screen and (max-width : 854px) {}

/* End responsive navbar */

/* *************************** */
/* *************************** */


/* Custom Layout list */
@media screen and (max-width : 1212px) {
    .custom-list-details .custom-list-details-card {
        width: 11.8rem;
        height: 14.5rem;
        margin: 5px;
        background: var(--gris-tres-clair);
        font-size: 13px;
    }
}

@media screen and (max-width : 854px) {


    .list-elements-details {
        margin: 5% 0%;
    }

    .custom-list-details .custom-list-details-card {
        width: 10rem;
        height: 19rem;
        margin: 5px;
        background: var(--gris-tres-clair);
        font-size: 13px;
    }

    .custom-list-details-card .status-products {

        top: -35%;
        left: 13%;

    }

    .status-popular {
        left: 22% !important;

    }

    .custom-list-details div img {
        /* margin-top: -18%; */

    }

    #btn-subscription {
        height: 1.5rem !important;

    }

    .pagination {
        width: 10%;
    }

    .horizontal-line {
        display: none;
    }

    .view-all {
        width: 25%;
    }

    .title-category img {
        width: 30px;
        margin: 10px;
    }

    .items-subs {
        display: grid;
        grid-auto-flow: column;
        grid-template-rows: repeat(2, auto);
        /* Définit 2 lignes */

    }

    .sidebar-user {
        width: 80%;
        margin: 0 5%;

    }

    @media screen and (max-width : 414px) {
        .list-elements-details {
            margin: 5% 1%;

        }

        .custom-list-details .custom-list-details-card {
            width: 8rem;

        }

        .custom-list-details .custom-list-details-card {
            width: 10rem;
            height: 18rem;
            margin: 5px;
            background: var(--gris-tres-clair);
            font-size: 13px;
        }

        .price-product {
            flex-direction: column;
            align-items: flex-start;
        }

        .custom-list-details-card #btn-subscription {
            height: 2rem !important;

        }

        /* 
        .product-rupture {

            width: 6rem;

        } */


    }

}

/* End Responsive  Custom Layout list */

/* *************************** */
/* *************************** */

/* Respinsive CustomListCard */

@media screen and (max-width : 854px) {


    .list-carte {
        width: 10rem;
        height: 12rem;
        margin: 5px;
        box-shadow: 0px 2px 5px 0px var(--noir);
    }

    .list-carte .card-carte {
        width: 100%;
        height: 70%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .list-carte .card-carte img {
        position: relative;
        width: 70%;
        height: 7rem;
        margin-top: 0px;
    }

    .btn-carte {
        bottom: 30%;
        box-shadow: 1px 4px 10px var(--noir);
        width: 60%;

    }

    .pagination {
        width: 8%;
    }




    @media screen and (max-width : 414px) {
        .list-carte {
            width: 8rem;
            height: 10rem;
            margin: 5px;
            box-shadow: 0px 2px 5px 0px var(--noir);
        }

        .list-carte .card-carte {
            width: 100%;
            height: 60%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
        }

        .list-carte .card-carte img {
            position: relative;
            width: 55%;
            height: 5rem;
        }


    }



}

/* End About us */

/* *************************** */
/* *************************** */

@media only screen and (max-width : 768px) {
    .footer {
        padding: 1%;

    }

    footer .menu ul {
        margin: 0;
    }

    footer .logo {
        display: block;
        position: absolute;
        top: 20px;
        left: 20px;
    }


    /* .footer .logo .image {

        background: url("../Imgs/icons/logo-senpremium-mobile..svg") !important;
    } */

    footer .copyright {
        text-align: left;
        padding-left: 20px;
    }
}

/* End Footer */