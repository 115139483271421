/* HowShare style */
.HowShare {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2%;

}

.HowShare .image-container {
    width: 50%;
    height: 28rem;
    padding: 0%;
    box-shadow: 2px 2px 10px ;
    display: block;

}
.HowShare .image-container-mobile {
    display: none;
}


.container-zigzag {
    width: 14%;
}

.HowShare .zigzag {
    width: 130%;
    position: relative;
    right: 2%;
}


.HowShare .sidebar {
    width: 40%;
    height: 32rem;
    background-color: #FFEFDD;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sidebar .btn-how-it-work {
    width: 100%;
    height: 7rem;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 4px;
    color: gray;
}

.btn-how-it-work .choice-number {
    width: 30%;
    font-size: 7rem;
    text-align: center;
}

.btn-how-it-work .details-choice {
    width: 60%;
}

.btn-how-it-work .details-choice p{
    width: 90%;
    text-align: start;
}

.sidebar #btn-active {
    background: var(--orange-brule);
    color: var(--blanc);

}





/* Sidebar */

.side-bar {
    width: 24%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 32%;
    border-radius: 4px;
    background: #FFEFDD;
}

.nav-side-bar {
    width: 14rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin: 3%;
    text-decoration: none;
    color: var(--blanc);
    font-weight: 700;
    font-size: 22px;
    background: var(--orange-brule);
    box-shadow: 0px 8px 16px 0px var(--noir);


}

.link-inactive {
    width: 14rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin: 3%;
    text-decoration: none;
    color: var(--noir);
    font-weight: 700;
    font-size: 22px;
    background: var(--gris-clair);
    box-shadow: 0px 8px 16px 0px var(--noir);

}

.share-layout {
    width: 75%;
    margin-left:25%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.share-layout h2{
    margin: 12% 0% 3% 0%;
}

.share-layout .content-items{
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.btn-next {
    text-decoration: none;
    padding: 1rem;
    color: var(--noir);
    font-weight: 600;
    border-radius: 4px;
    box-shadow: 0px 8px 16px 0px var(--noir);

}

#card_active {
    background-color: var(--orange-brule);
    color: var(--blanc);
}

.btn-change-component {
    width: 86%;
    display: flex;
    justify-content: space-between;
}


/* Share category and type */

.share-layout .content-items .card-item {
    width: 10rem;
    height: 8rem;
    margin: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    color: var(--noir);
    background-color: #FFF;
    border: solid 1px var(--bleu-ciel);
    border-radius: 4px;
    box-shadow: 0px 8px 16px 0px rgb(217, 217, 217);


}

.share-layout .content-items #card-active {
    background: var(--orange-brule);
}

.share-layout .content-items #card-inactive {
    background: var(--background-white);
}

.share-type-content .content-items .card-item img {
    width: 6rem;
}
.price-img-share-value{
    display: none;
}

/* share-details */
/* .share-details-content{
} */
.share-details-infos{
    width: 86%;

}
.share-details-content span {
    color: var(--orange-brule);
}
#share-price{
    color: var(--bleu-ciel);
}

.share-details-infos form .form-child {
    width: 100%;
    margin: 3% 0;
    display: flex;
    justify-content: space-between;

}


.share-details-infos form .form-child div {
    width: 46%;
    display: flex;
    flex-direction: column;

}

.share-details-infos form .form-child div input {
    width: 100%;
    height: 3rem;
    margin-left: 0;
    border:solid 1px var(--bleu-ciel);
    border-radius: 4px;

}

/* share-resume */
.share-resume{
    width: 75%;
    margin-left:25%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.share-resume .card-resume {
    background-color: var(--blanc);
    width: 80%;
    min-height: 25rem;
    margin-top: 13%;
    padding: 1% 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    box-shadow: 0px 8px 16px 0px var(--noir);

}

.share-resume .card-resume .details-share-resume{
    width: 90%;
}
.elements-details-share-resume{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.share-resume .card-resume div p {
    margin: 1%;
}

.img-share {
    width: 40%;
}

.share-resume .card-resume span {
    font-weight: 700;
}

.share-resume .card-resume .btn-share {
    width: 10rem;
    height: 3rem;
    margin: 2%;
    background: var(--orange-brule);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--blanc);
    text-decoration: none;
    font-size: 25px;
    font-weight: 600;

}




/* *************************** */

/* *************************** */

/* *************************** */

/* *************************** */

/* *************************** */

/* *************************** */



/* Responsive APP */


/* How it work : how share and how subscription */
@media screen and (max-width : 800px) {

    .HowShare {
        width: 90%;
        display: flex;
        flex-direction: column;
    }

    .HowShare .sidebar {
        width: 100%;

    }
    .container-zigzag {
        width: 20%;
    }

    .HowShare .image-container {
        width: 90%;
        height: 40rem;
        box-shadow: 2px 2px 10px ;
        display: none;

    }
    .HowShare .image-container-mobile {
        display: block;
        width: 90%;
        box-shadow: 2px 2px 10px ;


    }

}

@media screen and (max-width : 1024px){

    .share .share-content{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .side-bar{
        position: relative;
        height: auto;
        flex-direction: row;
        flex-wrap: wrap ;
        margin-top: 15%;
        width: 90%;
        padding: 2%;

    }
    .nav-side-bar {
        margin: 2%;
        font-weight: 600;

    }
    .link-inactive{
        margin: 2%;
        box-shadow: none;
        font-weight: 500;

    }
    .share-layout {
        width: 100%;
        margin:0%;
    }
    .share-layout h2{
        width: 90%;
        font-size: 25px;
        text-align: center;
    }

    .share-layout .content-items .card-item {
        width: 8rem;
        height: 6rem;
        margin: 2%;
    
    
    }
    .share-type-content .content-items .card-item img {
        width: 4rem;
        max-height: 4rem;
    }

/* Share Details */
    .share-details-infos form .form-child {
        flex-direction: column;
    
    }
    .share-details-infos form .form-child div {
        width: 100%;
        margin: 1%;
    
    }
    .share-details-infos form .form-child div input {
        margin: 1%;
    
    }

    /* Share resume */

    .share-resume{
        width: 40rem;
        margin:0%;

    }
    .elements-details-share-resume{
        flex-direction: column;
        align-items: flex-start;
    }

}

@media screen and (max-width : 768px) and (min-width : 430px){
    .share-resume{
        width: 30rem;

    }
}

@media screen and (max-width : 430px){
    .side-bar{
        margin-top: 25%;
    }

    .share-resume{
        width: 20rem;
    }
}


