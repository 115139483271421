/* .banner-referral{
    background-color: aqua;
} */

.global-container-referral{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../Imgs/bg-decoration.png");

}
#referral-container{
    margin-top: 20vh;
    display: flex;
    justify-content: space-around;
    width: 90%;

}

.layout-banner-referral{
    width: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
#img-referral-banner{
    width: 80%;
    height: 85%;
    position: relative;

}
.news-in-referral{
    width: 120px;
    position: relative;
    right: 70px;
    top: 5px;

}
.title-referral {
    margin: 20px 0;
    font-size: 45px;
    font-weight: 750;
    line-height: 40px;
    width: 120% !important;
    
}
.title-referral .invite-friend{
    color: var(--orange-brule);
}
.title-referral .invited-referral{
    color: var(--bleu-ciel);
}
.new-user-by-referral{
    font-size: 22px;
    line-height: 35px;
}
.off5{
    color: var(--bleu-ciel);
}

.win-reduction{
    font-size: 32px;
    font-weight: 600;
    
}
.infos-referrals{
    margin: -4px;
    font-size: 18px;
    width: 120%;
    font-weight: 400 !important;
    line-height: 20px;
    padding-top: 15px;
}

.referral-description {
    font-size: 30px;
    font-weight: 750;
    line-height: 40px;
    width: 100%;
    color: black;
}

.reduction5 {
    color: var(--bleu-ciel);

}

.item-details-referral{
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
}

.item-details-referral .new-user-by-referral{
    font-weight: bold;
    font-size: 22px;
    color: var(--bleu-ciel);
    margin-top: 8px;
}

.item-details-referral .icon-details-referral{
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #2aa6df65;
}
.icon-details-referral{
    font-size: xx-large;
}

.copy-link-referral{
    display: flex;
    gap: 10px;
}
.send-link-referral {
    display: flex;
    justify-content: space-between;

}

.send-link-referral  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: solid 1px var(--bleu-ciel);
    border-radius: 4px;
    width: 300px;
    height: 1.4rem;
    gap: 2px;
}

.send-link-referral p {
    position: relative;
    font-size: 16px;
    top: 7px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.share-referral-link {
    padding: 6px 8px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 120px;
    color: var(--noire);
    font-weight: 500;
    border-radius: 4px;
    background-color: var(--orange-brule);
    height: 2rem;
    font-size: 18px;
    cursor: pointer;

}
.copy-popup-referral{
    position: relative;
    top: 6px;
    right: 15px;
}
.icon-offre{
    width: 40px;
    height: 40px;
}
.signup-referral{
    text-decoration: none;
    background-color: var(--orange-brule);
    color: var(--noire);
    font-size: 18px;
    font-weight: 600;
    width: 200px;
    height: 2rem;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;

}
#new-user-signup{
    background-color: var(--bleu-ciel);
    color: var(--blanc);
}
.link-signup-referral{
    display: flex;
    justify-content: center;
    align-items: center;

    text-decoration: none;
    color: var(--noire);
    font-size: 22px;
}
.chevron-right{
    align-items: center;
    margin-bottom: 12px;
}
.faqs-referrals{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    background: none ;
    margin: 70px 0;

}
.faq-referrals-content{
    width: 90%;
}
.faq-referrals-card{
    border-radius: 8px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.158);
    margin: 20px 0;
}


@media screen and (max-width : 576px){

    #referral-container{
        flex-direction: column !important;
        padding: 2%;
        margin-top: 12vh;

    }
    .news-in-referral{

        right: 25px;
    }
    .copy-link-referral{
        flex-direction: column;
        width: 100%;
    }
    .layout-banner-referral{
        display: none;
    }
    
    .title-referral {
        font-size: 35px;
        width: 100% !important;
    }
    .win-reduction{
        font-size: 25px;
        
    }
    .infos-referrals{
        width: 100%;

    }
    .send-link-referral  {

        padding: 12px;
        width: 93%;

    }
    
    .share-referral-link {

        justify-content: center;
        gap: 10px;
        width: 95%;
        font-size: 22px;
    
    }
    .faqs-referrals{
        width: 100%;
        margin: 30px 0 ;
    
    }


}

