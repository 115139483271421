@import url('./Variables.css');

/* Product Main Layout's style */
* {
    font-family: Montserrat;

}

/* * {
    outline: 1px solid red;
  }
*/


/* layout-banner-product */

#banner-product {
    background: #E6F7FE;
    height: 700px;
    width: 100%;
    /* margin: 0 -1%; */
}
#referral-container{
    background-image: url("../Imgs/bg-referral.png") !important;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background: white;

}

@media screen and (max-width : 1512px) {
    .banner-product {
        height: 92vh;
    }

}

.layout-banner-product {
    /* background: #E6F7FE; */
    position: relative;
    top: 5rem;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */

}

.layout-banner-product .layout-banner-left {
    position: relative;
    /* top: 12%; style avant promo */ 
    top: 9%; /* style promo*/
    width: 48%;
    height: 50%;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}

.layout-banner-left .intro-banner-product {
    font-size: 50px;
    font-weight: 750;
    line-height: 70px;
    width: 100%;
    justify-content: center;
}

.layout-banner-left .intro-banner-product #gift-card {
    color: var(--bleu-ciel);
}

.layout-banner-left .description-banner-product {
    font-size: 18px;
    line-height: 40px;
    width: 75%;
    justify-content: center;
    color: #7C7C7C;
}


.banner-product .search-subscription-product {
    position: relative;
    width: 90%;
    display: flex;
    justify-content: center;
    border-radius: 4px;

    /* margin: 1% 0; */
}

.search-subscription-product input {
    width: 100%;
    height: 3rem;
    border: none;
    border-radius: 4px 0 0 4px;
    font-size: 18px;
    background: var(--gris-clair);

}

.content-icon-search {
    width: 15%;
    height: auto;
    background-color: var(--bleu-ciel);
    border-radius: 0 4px 4px 0;
    display: flex;
    justify-content: center;
    align-items: center;

}

.search-subscription-product .icon-search {
    width: 2rem;
}

/* Style du contenu du popup */

.popup-overlay {
    position: absolute;
    top: 100%;
    width: 100%;
    height: auto;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    /* Permet le défilement vertical si le contenu dépasse la hauteur maximale */

}

.popup-content {

    width: 100%;
    /* Largeur du contenu du popup */
    background-color: rgba(255, 255, 255, 0.774);
    /* Fond gris semi-transparent */
    border-radius: 4px;

}

/* Style pour empêcher les clics à travers le popup */
.popup-content:hover {
    cursor: auto;
}

/* Scroll */

.layout-banner-product .layout-banner-right {
    width: 48%;
    display: flex;
    justify-content: end;
}

.layout-banner-product .layout-banner-right img {
    width: 85%;
    height: 80%;
}


/* categories scroll */
.product-categories_scroller {
    width: 80%;
    margin: 0 10%;

    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;

}

.scroll-cat {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;

}

.scroller__categories__items {
    width: 100%;
    padding: 0.5% 2%;
    height: 60%;
    border-radius: 8px;
    display: flex;
    justify-content: center;

}

.product-categories_scroller::-webkit-scrollbar {
    display: none;
}

.scroller__categories__items:hover {
    transform: scale(1.1);
}

.scroller__categories__items a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 100%;
    text-decoration: none;
    color: var(--blanc);
    font-size: 14px;
    font-weight: 600;
}

.scroller__categories__items a img {
    width: 20px;
    height: 20px;
    margin: 0 10px;
    /* margin: 0 5px; */
}

.category-orange {
    background: var(--orange-brule);

}

.category-blue {
    background: var(--bleu-ciel);

}



/* End Scroll */
.categories-nav {
    position: relative;
    top: -8%;
}

.frequently-product {
    position: relative;
    text-align: center;
    /* background-color: var(--background-white); */
    background-size: 10% 10%;
}
.title-section-promo{
    position: relative;
    top: 20px;
}
/* .frequently-product h1 {
    position: relative;
    top: 15px;
} */

.categories-product {
    width: 100%;
    position: relative;
    /* In promo */
    top: -10%;
    display: flex;
    flex-direction: column;

}

/* .categories-product-elements {
} */

/* Products CAtegories */

.products-category {
    position: relative;
    top: 6rem;
    width: 100%;
}

.sous-navbar {
    width: 23%;
    display: flex;
    justify-content: flex-start;
    margin-left: -1.5%;

}

#sous-navbar-sub {

    position: relative;
    right: 28%;

}

.sous-navbar .link-sous-navbar {
    color: #7C7C7C;
    text-decoration: none;
    margin: 0 2%;
}

#activeLink {
    color: var(--bleu-ciel);

}

.categories-content {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
}

.categories-content .side-bar-categories {
    background-color: var(--gris-clair);
    width: 18%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    height: 140vh;
    position: relative;
    margin: 1% 0;
    border-radius: 4px;
    padding: 1%;

}

.child-side-bar {
    text-decoration: none;
    background-color: var(--blanc);
    width: 95%;
    padding: 4%;
    margin: 2%;
    font-weight: 500;
    color: var(--noire);
    border-radius: 4px;
    text-align: start;
}

.child-side-bar img {
    display: none;
}

.side-bar-categories .link-side-bar-categories {
    text-decoration: none;
    color: var(--noire);

}

.category-dropdown,
.sub-category-row {
    display: none;
}

.category-dropdown .category-dropdown-options {
    display: none !important;
}

.sub-categories {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
}

.sub-categories button {
    margin: 1% 0;
    padding: 2%;
    width: 80%;
}


#activeLinkSideBAr {
    background-color: var(--orange-brule);
}

.subActive {
    background-color: var(--bleu-ciel) !important;
}

.category-products {
    width: 100%;
    margin: 1% 0;
}

.custom-products-categories {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

}

.search-bar-product-cat , .category-dropdown {
    width: 40%;
    height: 3rem;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    margin-left: 15px;
    background: var(--gris-clair);
    color: var(--noir-charbon) !important;
}


/* *************************** */

/* *************************** */

/* *************************** */

/* *************************** */

/* *************************** */

/* *************************** */



/* Responsive APP */

/* BAnner product  */
@media screen and (max-width : 800px) {
    .layout-banner-product {

        flex-direction: column-reverse;
        align-items: center;
        height: 65vh;
    }

    #content-product {
        height: 75vh;
    }

    .layout-banner-product .layout-banner-left {
        flex-direction: column;
        justify-content: space-between;
        align-items: center !important;
        width: 100%;
        height: 40% !important;
        /* top: 0%; */ /* Style before promo*/
        top: -3%; /* during promo*/
    }

    #content-left-products {
        /* height: 40%; */
        margin: 0;
    }

    .categories-nav {
        top: 14%;
    }

    .see-all-products {
        position: relative;
        /* Style before promo */
        /* top: 40px; */
        width: 80%;
    }


    .product-categories_scroller {
        width: 100%;
        margin: 0;

    }

    .sub-categories {
        flex-direction: row;
        flex-wrap: wrap;

    }

    .sub-categories button {
        margin: 1% 0;
        padding: 0%;
        width: 40%;
    }



    .frequently-product {
        top: 20px;
    }

    .categories-product {
        /* margin: 24% 0; */
        /* In promo */
        margin: 40% 0;

    }

    .categories-product-elements.active {
        padding-top: 16%;
        font-size: 15px;
    }

    .layout-banner-product .layout-banner-right {
        width: 100%;
        height: 50vh;

    }

    .layout-banner-product .layout-banner-right img {
        width: 90%;
        height: 100%;
    }


    .content-icon-search {
        width: 15%;
        height: auto;
        background-color: var(--orange-brule);
        border-radius: 0 4px 4px 0;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    /* Mini navigation for the categories products */
    .products-category {
        width: 105% !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 !important;
    }

    .category-dropdown{
        display: block;
        /* padding: 1.3%;
        width: 300px;
        margin-left: 30px !important;
        margin: 1.5% 0; */
    }
    .category-products {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .search-bar-product-cat , .category-dropdown{
        width: 84%;
        margin-left: 0;
        margin: 2% 0;



    }
    .sub-category-row {
        display: block;
        width: 100%;
    }

    .side-bar-categories {
        display: none !important;
    }


    .child-dropdown-bar {
        position: relative;
        width: 100%; /* Assurez-vous que la largeur est bien de 100% pour ne pas dépasser le parent */
        padding: 1% 0;
        height: 90px;
        display: flex; /* Utilisez flex pour faciliter l'alignement */
        align-items: center;
        gap: 20px;
        overflow-x: auto; /* Activer le scroll horizontal */
        overflow-y: hidden; /* Désactiver le scroll vertical */
    }
    
    .child-dropdown-bar::-webkit-scrollbar {
        display: none; /* Cacher le scroll sur Webkit (Chrome, Safari) */
    }
    
    #mini-nav-categories-products {
        background-color: var(--background-white);
        min-width: 150px; /* Utilisez min-width pour gérer la largeur minimale des éléments */
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    

    #mini-nav-categories-products img {
        width: 50px;
        height: 40px;
    }


    .subActive button{
        color: var(--blanc) !important;
    }
    .btn-mininav-dropdown {
        background: none ;
        font-size: 16px !important;
        font-weight: 600;
    }
    #option-dropdown-categories{
    }
    

}

@media screen and (max-width : 431px) {

    /* .categories-product-elements {
    } */
    .sous-navbar {
        width: 100%;
        margin: 2%;
        font-size: 14px;
        left: 8px;

    }

    #sous-navbar-sub {
        right: 0;

    }
    .title-section-promo{
        top: 0px;
    }

    .categories-product-elements.active {
        padding-top: 20%;
    }


    .layout-banner-left .intro-banner-product {
        font-size: 30px;
        font-weight: 750;
        line-height: 40px;
        width: 80%;
    }

    .layout-banner-left .description-banner-product {
        font-size: 14px;
        line-height: 20px;
    }

    .categories-content {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 0 !important;
        width: 100% !important;

    }

    .categories-content .side-bar-categories {
        width: 100%;
        height: 15vh;
        display: grid;
        grid-auto-flow: column;
        gap: 50px;
        overflow-x: scroll;
        overflow-y: hidden;
        scrollbar-width: none;

    }

    .child-side-bar {
        width: 200px;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .child-side-bar img {
        display: block;
        width: 70px;
    }

    /* 
    .side-bar-categories .link-side-bar-categories {
        width: 200px;
    } */
    .custom-products-categories {
        width: 100%;
        justify-content: center;
    }






}





/* End Banner Product */