/* subscription  Informations product for the subscription*/
.subscription {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background: var(--background-white); */

}
.popup-cart{
    background-color: var(--gris-fumee);
    opacity: 70%;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 99;
    overflow: hidden;
}

.header-subscription {
    width: 98%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 6%;
    padding: 1%;
    z-index: 99 !important;
}

.subscription-content-layout {
    /* width: 90%; */
    height: auto;
    border-radius: 4px;
    box-shadow: 0px 1px 10px 0px var(--box-shadow-color);

}

.subscription-container {
    width: 86%;
    padding: 0 2%;
    background: var(--blanc);
}

.card-subs {
    position: relative;
    top: -30px;
    width: 86%;
    margin-top: 2%;
    padding: 2%;
    box-shadow: none;
}



.subscription-delivery {
    width: 70%;
    display: flex;
    justify-content: space-around;
    background: var(--blanc);
}

/* .subscription-delivery p {
    width: 85%;
} */



.subscription-content-details {
    display: flex;
    gap: 30px;
}

.subscription-content-layout .content-layout {
    width: 50%;
    padding: 2% 3%;
}

.subscription-content-layout .content-left {
    width: 48%;
    text-align: left;
    white-space: pre-line;
    line-height: 30px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    /* align-items: end; */
}

.content-left-container-top {
    background-color: #F6F7F9;
    width: 94%;
    padding: 5% 4%;
    margin-top: -4%;
    border-radius: 8px;
    border: solid 0.5px #E1E3E6;
}

.content-left-container-bottom {
    width: 100%;
    padding: 5% 0;
    margin-top: -4%;
}

.content-wish-icon {
    background-color: var(--blanc);
    /* opacity: 80%; */
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: -10px;
    margin-right: -20px; */
}

.wish-icon {
    cursor: pointer;
    font-size: 1.7rem;
    color: var(--gris-fumee);
    position: absolute;
}

.wish-icon-active {
    color: red;
}

.subscription-content-layout .img-product {
    /* padding: 2% 0; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.subscription-content-layout .img-product img {
    width: 70%;
    max-height: 70%;
    position: relative;
    margin: 2% 0;
}

#img-product-subscription {
    bottom: 18px;

}

.product-name {

    padding: 5% 0;
}

.content-right-details {
    display: flex;
    gap: 10px;
    width: 100%;

}

.content-right-details-mobile {
    display: none;
}

.content-right-details-child {
    width: 48%;
    height: 55px;
    display: flex;
    align-items: center;
    gap: 15px;
    background-color: #F6F7F9;
    border-radius: 4px;
    padding: 1%;

}

.content-right-details-child-icon {
    font-size: 32px;
}

.content-right-details-child div {
    /* margin-top: 10px; */
    font-size: 12px;
    line-height: normal;
    height: 35px;
    display: grid;
    align-items: center;

}


.text-content-right-details {
    font-weight: bold;
    font-size: 12px;
}


.subscription-content-layout .content-right {
    width: 48%;
    height: auto;
    box-shadow: 0px 5px 5px 0px var(--gris-clair);
    white-space: pre-line;
}


.product-price-inSubscription {
    display: flex;
    /* justify-content: space-around; */
    gap: 30px;
}

.subscription-content-layout .content-left .in-promo-subscription {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 25px;
    width: 110px;
    position: relative;
    padding: 1%;
    background-color: var(--gris-tres-clair);
    border-radius: 30px;
    font-weight: 700;
    color: var(--orange-brule);
}

.subscription-content-details .details-promo-wishlist {
    display: flex;
    width: 98%;

}

.promo-wishlist {
    justify-content: space-between;
}

.no-promo-wishlist {
    justify-content: end;
}

.similar-products {
    margin: 10px 0;
    background-color: var(--gris-tres-clair);
    border-radius: 8px;
    width: 100%;
    padding: 3% 0;
}

.similar-products .drop-down-products {
    width: 98%;
    margin: 2%;
}


.validate-subscription-infos {
    width: 98%;
    margin: 2% 0;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.product-in-rupture{

}

.validate-subscription-btn {
    width: 45%;
    background: var(--orange-brule);
    border-radius: 4px;
    padding: 0.6rem 0;
    text-decoration: none;
    font-weight: 500;
    font-size: 18px;
    color: var(--blanc);
}

#btn-buy-now {
    background-color: #00ABF3;
}

#btn-in-rupture {
    background-color: red;
    width: 100%;
    cursor: not-allowed;
    opacity: 0.5; /* Facultatif pour indiquer visuellement que c'est désactivé */


}

.subscription-content .content-left .content-left-top .content-left-title {
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#content-product-description {
    font-weight: 600;
}

.subscription-content .content-left .content-description {
    margin: 2% 0;
    text-align: left;
    white-space: pre-line;

}

#content-description {
    margin: 3% 0;
}

.content-payment {
    width: 90%;
}

.title-payment {
    margin: 2%;
    text-align: center;
}

.description-payment {
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: center;
}

.payment-secure-img {

    width: 100%;
    display: flex;
    justify-content: space-around;
    border-radius: 8px;
    border: solid 1px var(--gris-clair);
    padding: 1% 0;
}

.payment-secure-img img {
    width: 100px;

}


#secure {
    color: var(--bleu-ciel);
}

#payment {
    color: var(--orange-brule);
}

.content-option-phone-pc {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.type-abonnement {
    display: flex;
    justify-content: flex-end;
    border-radius: 8px;
    border: solid 1px var(--noir);
    padding: 2% 4%;
    width: 96%;
}

.details-option-phone-pc {
    width: 96%;
    padding: 2%;
}

.type-abonnemen-enable .validate-subscription-btn {
    width: 40%;
    border-radius: 4px;
}

.option-type-abonnement {
    width: 100%;
    display: flex;
}

.option-type-abonnement img {
    margin: 2%;
    width: 12rem;
    height: 2.6rem;
    cursor: pointer;
}

.attention {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    color: var(--orange-brule);
    font-weight: 550;
    padding: 2%;
    margin: 2% 0;
    border-radius: 4px;
    border: solid 1px var(--orange-brule);
    background-color: #fef5ea;

}

.attention img {
    width: 20px;
    padding: 1rem;
    background: var(--orange-brule);
    border-radius: 50px;
    margin-right: 20px;
}

.attention .avertissement {
    color: var(--noir) !important;
}

.option-netflix-active {
    font-weight: bold;
    color: var(--noir);
}

.style-price {
    font-weight: bold;
    color: var(--orange-brule);

}



.faq-title {
    text-align: center;
}

.faq-content {
    border: solid 1px var(--gris-clair);
    border-radius: 8px;
    margin: 2% 0;
}

.faq-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    padding: 4px 10px;
    margin: 15px;
    height: 40px;

}

.faq-item p {
    width: 80%;
}



.faq-description {
    color: #4b4b4b;
    padding: 0px 20px;
    width: 90%;
    line-height: 30px;

}

.arrow-faq {
    margin: 15px;
    font-size: 30px;
    font-weight: 300;
    cursor: pointer;
}

.chevron {
    margin-top: -10px;
}


/* End Infos subscription */

/*******************************************/
/*******************************************/

/* New Subscription interne subscription: user connected */
.subscription-levels {
    width: 45%;
    margin-top: 7.5%;
    padding: 1% 2%;
    display: flex;
    justify-content: center;
    background-color: var(--blanc);
}

.interne-subscription {
    width: 45%;
    height: auto;
    margin: 1%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    background-color: var(--blanc);

}

.subscription-options-details {
    display: flex;
    justify-content: space-between;
    margin: 1% 0;
    font-size: 15px !important;
}

.parrainage-reduction {
    background-color: #F39329;
    /* width: 70px; */
    align-items: center;
    padding: 5px;
    border-radius: 6px 10px;

}

.counter {
    width: 30%;
    display: flex;
    justify-content: space-between;

}


.counter .counter-params {
    width: 3rem;
    height: 3rem;
    background: #FFEFDD;
    font-size: 25px;
    text-align: center;
    margin: 3px 0;
}

.total-price {
    color: var(--bleu-ciel);
}

#quantity {
    background: none;
}

#btn-payer {
    width: 100%;
    position: relative;
    right: 10px;
}

.interne-subscription .sub-inprogress {
    cursor: pointer;
    text-decoration: underline;
    margin: 20px 0;
    color: #00ABF3;
}


.form-reserve-license .details-form-reserve-license {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
}

.form-reserve-license .details-form-reserve-license input {
    /* width: 80%; */
    height: 2rem;
}

.coupon-icon {
    color: green;
    font-size: 20px;
    margin-right: 10px;
}

.add-promo-code input {
    width: 40%;
    height: 2rem;
    font-size: 15px;
    background-color: var(--gris-clair);
    border: none;
    border-radius: 4px;
}

.add-promo-code button {
    width: 40%;
    height: 2rem;
    font-size: 15px;
    background-color: var(--orange-brule);
    border: none;
    border-radius: 4px;
    color: var(--blanc);
    font-weight: 600;
    margin: 10px;
}

/* End Sub Interne */

/*******************************************/
/*******************************************/

/* New subscription ; Extern subscription : user not connected */

.subscription-content-unknownSub {
    width: 90%;
    height: auto;
    margin: 1% 5%;
    display: flex;
    align-items: center;
}

.content-right-unknownSub {
    width: 25%;
    height: auto;
    margin: 1%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: var(--blanc);
}

#apply-promo {
    background-color: #2aa6df70;

}

.promo-code {
    width: 96%;
    padding: 2%;
    margin: 3% 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fef4ea;
    cursor: pointer;
}

.promo-code div {

    display: flex;
    align-items: center;

}

.promo-code div h3 {
    margin-left: 20px;
    cursor: pointer;
}

.points-win {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2% 0;
}

.points-win-details {
    display: flex;

}

.points-win-details .iocn-win {
    color: green;
}

.points-win-details h4 {
    margin: 0 5px;

}

.content-left-unknownSub {
    width: 75%;
    height: auto;
    margin: 1%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    background-color: var(--blanc);
    justify-content: center;
}


.content-left-unknownSub form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.options-subscription {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

}

.wish-renewal-btn .link-btn-wish {
    background-color: var(--orange-brule);
    width: 40%;
    border-radius: 4px;
    padding: 2%;
    text-decoration: none;
    color: var(--blanc);
    font-size: 18px;
    font-weight: 600;
}


.options-subscription-button {
    /* width: 60% !important; */
    height: 3rem;
    background-color: var(--orange-brule);
    color: var(--blanc);
    border-radius: 2px;
    margin: 2% 0;
    padding: 2% 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}

.options-subscription-button span {
    font-size: 20px;
    font-weight: 600;
    margin-left: 10px;
    margin-right: 50px;

}

.options-subscription-button:hover {
    background-color: var(--bleu-ciel);
    color: var(--blanc);
    /* padding: 2% 0; */
}

.content-left-unknownSub form div {
    width: 100%;
    font-size: 14px;
}

.content-left-unknownSub form .elements-from {
    display: flex;
    justify-content: space-between;
    margin: -20px 0 !important;
}

.content-left-unknownSub form .elements-from div {
    margin: 4% 0;
    /* width: 100%; */
}

#phone-number-input {
    position: relative;
    top: 1.9rem;
}

#phone-number-input label {
    position: relative;
    top: 0.55rem;
}

.content-left-unknownSub form div input {
    width: 98%;
    height: 3rem;
    font-size: 15px;
    background-color: var(--gris-clair);
    border: none;
    border-radius: 4px;

}

.elements-from div input {
    width: 96% !important;
}

#confirm-email {
    color: red;
}

.cgu #cgu {
    font-size: 14px;

}



.checkbox input {
    width: 22px !important;
    cursor: pointer;
    background-color: #4b4b4b;

}


.checkbox label {
    position: relative;
    bottom: 18px;
    left: 20px;
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 8px;
    padding-bottom: 10px;
    width: 90%;
    height: 95vh;
    max-width: 600px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    display: flex;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    z-index: 999;
}



.wish-renewal {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    display: flex;
}

.wish-renewal p {
    color: #707070;

}

.wish-renewal-details {
    width: 90%;
    padding: 0 3%;
}

.wish-renewal-details p {
    font-size: 15px;
    margin-bottom: 2%;
    margin: 1%;

}

.wish-renewal-title {
    background-color: var(--orange-brule);
    color: var(--blanc);
    width: 90%;
    text-align: center;
    font-size: 12px !important;
    padding: 5%;
    display: flex;
    border-radius: 10px 10px 0 0;

}

.wish-renewal-old {
    width: 90%;
    padding: 5%;
    display: flex;
}

.wish-renewal-old {
    width: 90%;
    padding: 2%;
    display: flex;
}

.wish-renewal-old .line {
    width: 20px;
    margin: 0 3%;
    border-radius: 10px;
    background-color: var(--orange-brule);
}

.img-arrow {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    position: relative;
    /* left: 8%; */
    background-color: var(--orange-brule);
    border-radius: 100px;
    padding: 0.5%;
}

.wish-renewal-btn {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

/* .wish-renewal-btn {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
} */

.link-btn-wish {
    background-color: var(--bleu-ciel);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
}

.link-btn-close {
    background-color: white !important;
    color: var(--orange-brule) !important;
    border: solid 1px var(--orange-brule);
}

.link-btn-wish:hover {
    background-color: var(--bleu-ciel);
}

/* End unknown subscription  */
/*******************************************/
/*******************************************/

/* Success Subscription : payment make*/

.success-subscription-layout {
    width: 90%;
    margin: 8% 5%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.content-success-left {
    width: 30%;
    height: 100vh;
    padding: 2%;
    background: var(--blanc);

}

.more-infos-content-success {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

}



.img-success-subscription {
    font-size: 15px;
    margin: 2% 0;

}

.img-success-subscription img {
    width: 50%;
    height: auto;
}

#total-payment {
    color: var(--bleu-ciel);
}

.close-btn-success-subscription {
    width: 100%;
    margin-top: 5%;
}

.validate-subscription-btn-close {
    width: 100% !important;
    background-color: var(--orange-brule);
    padding: 2% 0;
    text-align: center;
    border-radius: 4px;
}

#btn-close {
    text-decoration: none;
    color: var(--blanc);
    font-weight: 550;
}

.content-success-right {
    height: auto;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    gap: 15px;
    align-items: center;
    width: 86%;
    margin: 0 1%;
}

.client-infos-success-payment {
    background-color: #00000008;
    margin: 2% 0;
    padding: 4%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 4px;
}

.client-infos-success-payment p {
    font-size: 14px;
    width: Auto;
}

.content-infos-success-payment {
    width: 94%;
    height: auto;
    box-shadow: 0px 1px 10px 0px #f1f0f0;
    padding: 1%;
    border-radius: 8px;

}

.request-support-content {
    width: 100%;

}

.order-success-details-product {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* margin: 10px 0; */
    font-size: 14px !important;
    font-weight: 500;
}

.order-success-details-product img {
    width: 40%;
    height: 5.3rem;
    border-radius: 4px;
}


.details-content-success {
    width: 90%;
    height: auto;
    background: var(--blanc);
    box-shadow: 0px 1px 10px 0px #f1f0f0;
    padding: 2%;
    margin: 0 1%;
    border-radius: 4px;
}

.winner-points {
    color: var(--bleu-ciel);
    font-weight: 600;
}

.details-content-success p {
    white-space: pre-line;
}

.details-content-success h3 {
    white-space: pre-line;
    color: var(--bleu-ciel);
}

.details-content-success h5 {
    white-space: pre-line;
}

.order-validate {
    display: flex;
    width: 98%;
    /* margin-bottom: 20px; */
    align-items: center;
    background-color: #9DE4B83B;
    border-radius: 8px;
    padding: 8px;

}

/* .order-validate h2 {
    color: green;
} */

.order-validate img {
    width: 45px;
    height: 45px;
    margin-right: 20px;
}

.details-validate-order {
    width: 98%;
    padding: 1%;
    height: auto;
    border-radius: 4px;
    box-shadow: 0px 1px 10px 0px var(--box-shadow-color);
}

.details-validate-order-content {
    width: 100%;
}

.request-support-content {
    display: flex;
    gap: 15px;
    margin: 15px 0;
}

.order-infos {
    display: flex;
    gap: 10px;
    width: 100%;
    height: auto;

}

.order-information {
    width: 90%;
    height: auto;
    margin: 2% 0;
    padding: 5%;
    background-color: #00000008;
    border-radius: 4px;
    box-shadow: 0px 4px 2px var(--gris-clair);

}


.view-order-information {
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-height: 120px;
}

.order-information h5 {
    white-space: pre-line;
    margin-left: 10px;


}

.copy-subKey {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 4px;
    padding: 5px;
    background-color: var(--blanc);
    color: var(--orange-brule);
    width: 70px;
    cursor: pointer;
    margin: 10px 0;
}

#copy-order {
    position: relative;
    top: -15px;
}

.copy-subKey:hover {
    color: var(--blanc);
    background-color: var(--bleu-ciel);
}

.copy-popup {
    width: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: var(--blanc);
    padding: 10px 20px;
    border-radius: 4px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    opacity: 0.9;
    font-size: 14px;
    transition: opacity 0.3s ease-in-out;
    /* Transition pour l'effet de fondu */
}

.copy-icon {
    font-size: 20px;
    cursor: pointer;
    /* margin-left: 10px;  */
}



p {
    white-space: pre-line;

}

.order-information img {
    width: 40px;
    cursor: pointer;

}

.order-information div p {
    width: 90%;
    font-size: 12px;
    margin-left: 10px;
}

.icon-infos-validate-sub {
    color: var(--orange-brule);
    font-size: 40px;

}

.content-infos-noView-infos {
    font-size: 15px;
    height: 50px;
}

.infos-inclus-price {
    font-size: 8px;
}

.order-information #see-more {
    color: var(--orange-brule);
    font-size: 20px;
    font-weight: 1000;
    cursor: pointer;
}

#infos-send-email {
    font-size: 12px;
    margin: 1%;
    text-align: start !important;
}

.inprogress {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.in-progress-content {
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2%;
}

.in-progress-content h5 {
    color: #00ABF3;
    text-align: center;
}

a {
    color: #00ABF3;
    text-align: center;
}

.loading {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
}

.loading-gif {
    width: 3rem;
}

.order-details {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;
}

.order-options-details {
    display: flex;
    /* align-items: center; */
    /* justify-content: space-between; */
    gap: 10px;
    /* margin: 1%; */
}

.order-options-details-right {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    gap: 10px;
    /* margin: 1%; */
}

.order-options-details p {
    width: auto;
    font-size: 14px;
}

.order-options-details-text {
    font-weight: 600;
}

.order-details-content {
    background-color: #00000008;
    border-radius: 4px;
    padding: 20px;
    margin: 10px 0;
}


.request-support {
    width: 100%;

}

.request-support-title {
    display: flex;
    align-items: center;
    width: 200px;
    height: 2.4rem;
    padding: 4px;
    gap: 10px;
    border-radius: 4px;
    cursor: pointer;
    background: var(--gris-tres-clair);

}

.request-support-title img {
    width: 30px;
    height: 30px;
}

.active-btn-req-support {
    background: var(--gris-clair) !important;

}

.order-term {
    margin: 10px 0;
}

.request-support-rs {
    width: 100%;
    display: flex;
    gap: 20px;
    margin: 10px 0;
}

.request-support a {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 4px;
    width: 150px;
    height: 2.4rem;
    text-decoration: none;
    color: var(--noir);
    border-radius: 4px;
}

.request-support a img {
    width: 30px;
    height: 30px;
}

.request-support-whatsapp {
    background-color: #4AC75930;
}

.request-support-telegram {
    background-color: #2baeef41;
}

.send-infos-email {
    width: 100%;
}

/* End Success Subscription */

/*******************************************/
/*******************************************/

/* Failed Subscription */

.failed-subscription {
    display: flex;
    width: 80%;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--bleu-ciel);
}

.btn-failed {
    padding: 1.5%;
    margin: 2%;
    text-decoration: none;
    color: var(--blanc);
    font-weight: 700;
    border-radius: 4px;
    background-color: var(--orange-brule);
}


/* *************************** */

/* *********  Description Subscription *********** */


.description-subs {
    width: 100%;
    padding: 10px;
    position: relative;
    /* height: 40px; */

}

.description-subs-content {
    width: 92%;
    height: 35px;
    background: var(--blanc);
    border: solid 1px var(--bleu-ciel);
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-radius: 4px;
}

.description-subs-content p {
    font-size: 14px;
    margin: 0;
}

.description-subs-content img {
    width: 30px;
    height: auto;
    margin-left: 10px;
}

/* Conteneur de pagination */
.swiper-pagination {
    position: absolute;
    /* Permet de repositionner les points */
    /* bottom: -10px !important; */
    margin-bottom: -12px;
    text-align: center;
    width: 100%;
}

/* Style des points */
.swiper-pagination-bullet {
    background: #007bff;
    opacity: 0.6;
    width: 12px;
    height: 12px;
    margin: 0 5px;
}

/* Point actif */
.swiper-pagination-bullet-active {
    background: #0056b3;
    /* Couleur du point actif */
    opacity: 1;
}

/* *************************** */

/* *************************** */

/* Dropdown products */
.content-products-dropdown {
    width: 100%;
    font-weight: bold;
}

.products-dropdown {
    width: 96%;
    color: var(--noir);
    background-color: var(--blanc);
    font-size: 18px;
    padding: 5px;
    margin: -5px 0;
    border: none;
    border-radius: 8px;
    font-weight: bold;
}

.product-dropdown {
    color: var(--noir);
}

/* *************************** */

/* *************************** */

/* *************************** */

/* *************************** */

/* *************************** */

/* *************************** */


/* Responsive APP */

/* Responsive Connection*/
@media screen and (min-width : 768px) and (max-width : 1023px) {
    .subscription-delivery {
        width: 85%;
        margin-top: 18%;

    }

    .payment-secure-img {
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .payment-secure-img img {
        width: 12.5%;

    }

    #eMoney {
        width: 10%;

    }
}

/* @media screen and (min-width : 320px) and (max-width : 767px) { */
@media screen and (max-width : 767px) {

    .header-subscription {
        height: auto;
        flex-direction: column-reverse;
        z-index: 100;
        margin-top: 1%;
    }

    .header-subscription .sous-navbar {
        position: relative;
        bottom: 15px;
    }

    .subscription-delivery {
        width: 94%;
        margin-top: 20%;
    }

    .subscription-container {
        width: 90%;
    }

    .subscription-container-product {
        margin-top: -2rem !important;
    }

    .subscription-container-faqs {
        z-index: 10;
        margin-top: -2rem !important;

    }

    .card-subs {
        top: 0px;
    }

    .subscription-content-details {
        flex-direction: column;
        height: auto;
        width: 100%;
        font-size: 20px;

    }

    .subscription-content-details h4 {

        font-size: 15px;

    }

    .subscription-content-layout .content-layout {
        width: 100%;
        margin: 0 !important;
        padding: 0 !important;
    }

    .subscription-content-layout .content-layout h2 {
        font-size: 25px;
    }

    .subscription-content-details .content-left {
        align-items: center;
        margin: 2%;
    }

    .content-wish-icon {
        width: 32px;
        height: 32px;
        padding: 2px;
        margin-right: -14px;
    }

    .wish-icon {
        width: 22px;
    }

    .payment-secure-img img {
        width: 12.5%;
    }

    #eMoney {
        width: 10%;
    }

    /* .subscription-content-layout .content-right {
        width: 100% !important;
        position: relative;
        overflow-x: scroll;
        overflow-y: hidden;
        scrollbar-width: none; 

    } */
    /* .content-description-others{
        display: flex;
        flex-direction: column-reverse;
    } */

    .details-validate-order {
        width: 100%;
        box-shadow: none;
        background-color: none;
    }

    .details-validate-order-content {
        width: 100%;

    }

    .order-success-details-product {
        width: 100%;
    }

    .content-infos-success-payment {
        width: 98%;
        background: none !important;
        background-color: none !important;
        box-shadow: none;
        margin-left: -1%;

    }

    .content-infos-success-payment-item {
        width: 100%;
        border: solid 1px var(--gris-clair);
        border-radius: 4px;
        margin: 10px 0;

        padding: 0 1%;

    }

    .order-information {
        width: 90%;
    }

    .details-content-success {
        width: 98%;
        margin-left: -1%;
        /* background: none !important; */
        box-shadow: none;
    }

    .content-left-container-top {
        width: 100%;
        padding: 5%;
        margin-top: 4%;
        border-radius: 0px;
    }

    .content-right {
        box-shadow: none !important;
        position: relative;
        bottom: 50px;
    }

    .content-right-details-mobile {
        display: flex;
        margin: 20px 0;
    }

    .content-right-details-mobile .content-right-details-child {
        padding: 5px 10px;
        background-color: var(--blanc) !important;
        border: solid 1px #C8D6D9;
    }

    .content-right-details-web {
        display: none;
    }


    .content-right-details {

        /* display: grid;
        grid-auto-flow: column;
        position: relative;
        overflow-x: scroll;
        overflow-y: hidden;
        scrollbar-width: none; */
    }

    /* .content-right-details::-webkit-scrollbar {
        display: none;
    } */

    /* .content-right-details .content-right-details-child {
        width: 300px !important;

    }

 */

    /* .subscription-content-layout .content-right::-webkit-scrollbar {
        display: none;
    } */

    /* .subscription-content-layout .content-right .content-right-child{
        width: 250% !important;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    } */


    .similar-products {
        justify-content: center;
        margin: 15px 0;
    }



    .drop-down-products {
        width: 100% !important;
        margin-top: -15px;
        margin-bottom: 10px;

    }

    .validate-subscription-infos {
        justify-content: space-between;
        gap: 4px;
        height: auto;
        font-size: 10px;
    }

    #btn-in-rupture {
        width: 100% !important;
        /* margin-left: -6px; */
    }

    /* Description Subscription */
    .description-subs {
        /* width: auto; */
        margin-left: 2px;
        gap: 10px;
        padding: 0%;
    }

    .description-subs-content {
        margin-bottom: 20px;
    }



    .description-payment {
        flex-direction: column;
        margin-bottom: 20px;
    }
}

@media screen and (min-width : 0px) and (max-width : 319px) {
    .subscription-delivery {
        margin-top: 30%;

    }

    .validate-subscription-infos {
        flex-direction: column;
        justify-content: space-between;
        height: 6rem;

    }


    /* .subscription-content-layout .content-layout h2 {
        font-size: 10px;
    } */
}

/* End Subscription */

/* ************************************ */
/* ************************************ */

/* New Subscription interne subscription: user connected */
@media screen and (max-width : 1025px) {
    .interne-subscription {
        width: 90%;
        height: auto;
        margin: 3% 0 0 3%;

    }

}

/*End  New Subscription interne subscription: user connected */


/* ************************************ */
/* ************************************ */

/* New UnknownSubscription interne subscription: user not connected */

@media screen and (min-width : 769px) and (max-width : 1023px) {
    .subscription-levels {
        width: 84%;
        margin-top: 12%;

    }

    .content-left-unknownSub {
        /* width: 100%; */
        height: 30rem;

    }
}

/* @media screen and (min-width : 320px) and (max-width : 767px) { */
@media screen and (max-width : 768px) {
    .subscription-levels {
        width: 90% !important;
        margin-top: 15%;
        margin-left: 0.5rem !important;

    }

    .subscription-content-unknownSub {
        flex-direction: column-reverse;
        margin-left: 1.7rem !important;

    }

    .content-left-unknownSub {
        width: 100%;
        height: auto;
    }

    .options-subscription {
        width: 75% !important;
        padding: 2% 0 !important;
        margin: 0 15%;

    }


    .options-subscription-button {
        width: 100% !important;
        padding: 0 !important;

    }

    .options-subscription-button span {
        font-size: 16px;
        position: relative;
        left: 20px;
    }

    .content-left-unknownSub form div {
        width: 98%;
        font-size: 14px;
        padding: 1%;
    }

    .content-right-unknownSub {
        width: 100%;
        height: auto;

    }

    .content-left-unknownSub form div input {
        height: 2.8rem !important;

    }

    .attention {
        align-items: flex-start;
        font-weight: 400;
        font-size: 15px;
        position: relative;
        bottom: 33px;
    }

    .attention img {
        width: 18px;
        padding: 0.5rem;
        margin-right: 20px;
    }

    .type-abonnement {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;

    }


    .option-type-abonnement {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .option-type-abonnement img {
        width: 80%;
        height: 3.5rem;

    }

    .validate-subscription-btn {
        width: 65%;

    }

    #btn-payer {
        width: 96%;
        left: 0px;
    }

}

@media screen and (min-width : 0px) and (max-width : 586px) {
    .subscription-levels {
        width: 50%;
        margin-top: 30%;
    }

    .subscription-levels img {
        width: 90%;

    }

    /* .options-subscription-button {
        width: 100%;
        font-size: 12px;

    } */

    .content-left-unknownSub form .elements-from {
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        margin: 0 !important;
    }

    .content-left-unknownSub {
        height: 45rem;
    }



    .content-left-unknownSub form div input {
        width: 100%;
        height: 30px;
        font-size: 15px;
        margin-left: -1%;
    }

    .elements-from div {
        flex-direction: column;
        height: 40px;

    }

    .elements-from div input {
        width: 100% !important;
        height: 14px;
        padding: 0;

    }

}

@media screen and (max-width : 430px) {
    .modal {
        height: 75vh;
    }

    .wish-renewal-title {
        padding: 10% 5%;
        margin-top: -10%;
    }

    .wish-renewal-details div {
        margin: 8% 4%;
    }

    .wish-renewal p {
        font-size: 13px !important;
        width: 90% !important;
        margin: 2%;
        line-height: 20px;

    }

    .wish-renewal h3 {
        font-size: 15px;

    }

    .link-btn-wish {
        font-size: 12px !important;

    }
}

@media screen and (max-width : 414px) {
    .modal {
        height: 90vh;
    }

    .wish-renewal p {
        line-height: 15px;
    }

    .options-subscription {
        width: 80% !important;
        padding: 2% 0 !important;
        margin: 0 10%;

    }
}


/*End  New UnknownSubscription interne subscription: user not connected */

/* ************************************ */
/* ************************************ */

/* Validate Subscription  */

@media screen and (max-width : 769px) {
    .success-subscription-layout {
        width: 93%;
        margin: 25% -5px 0 0 !important;
        padding: 0 !important;
        display: flex;
        flex-direction: column-reverse;


    }

    .content-success-left {
        width: 98%;
        /* margin: 5%;
        padding: 4%; */
        height: auto;
        /* margin: 2% 0; */
        margin-left: -1%;


    }

    .content-success-right {
        width: 100%;
        height: auto;
        margin: 2% 0;
        /* padding: 4%; */
        background: none !important;
        box-shadow: none !important;
        background-color: none !important;
    }


    .order-infos {
        flex-direction: column;
        width: 100%;

    }

}

/* End Validate Subscription  */