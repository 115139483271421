.cart-content{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.cart-container{
    width: 90%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: space-between;
}
.cart-container-left {
    position: relative;
    width: 65%;
    height: auto;
}
.cart-container-right{
    position: relative;
    height: auto;
    width: 28%;
    padding: 1%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
#cart-amphy-style{
    display: none;
}
.similary-show-cart{
    position: relative;
}

.cart-container-left ul{
    width: 100%;
    list-style: none;
}
.cart-container-left ul li{
    width: 100%;
    margin: 2% 0;
    padding: 2%;
    border: solid 0.5px var(--gris-clair);
    border-radius: 8px;
    display: flex;
    align-items: flex-start;
}
.details-product-cart img{
    width: 20%;
}
.container-product-cart{
    display: flex;
    width: 100%;
    height: auto;

}

.details-product-cart{
    display: flex;
    width: 96%;

}
.product-cart-infos{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.product-cart-quantity , .product-cart-name {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    margin: 2%;
}
.product-cart-quantity {
    width: 15%;
    display: flex;
    justify-content: space-around;
}
.product-cart-quantity .counter-cart {
    width: 100%;
    display: flex;
    justify-content: space-between;

}
.product-cart-quantity .counter-cart button{
    width: 30%;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    justify-content: center;
}
.deleteProduct{
    cursor: pointer;
}

/* Show Cart */



.show-cart{
    background-color: var(--blanc);
    position: fixed;
    width: 30%;
    left: 48%;
    top: 5rem;
    padding: 1%;
    z-index: 100;
    border-radius: 8px;
}
.cart-container-show-cart{
    width: 100%;
}
.cart-container-show-cart ul{
    width: 100%;
    height: auto;
    list-style: none;
}
.cart-container-show-cart ul li{
    width: 100%;
    margin: 2% 0;
    border: solid 0.5px var(--gris-clair);
    border-radius: 4px;
    display: flex;
    align-items: flex-start;

}
.show-cart-content{
    display: flex;
    flex-direction: column;
}

.show-cart-content .cart-container-left ul li{

    background:  var(--background-white);
    
}
.redirect-show-cart{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}
.content-cart-amphy{
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 2% 0;

}
.btn-show-cart{
    font-size: 14px;
    background-color: var(--orange-brule);
    padding: 2% 1%;
    text-align: center;
    border-radius: 4px;
    text-decoration: none;
    color: var(--blanc);
    font-weight: 700;
}
#btn-view-products-cart{
    width: 230px;
    font-size: 18px;
    font-weight: 600;
}
.cart-container-show-cart .details-product-cart img{
    width: 5rem ;
    padding: 2% 0;
}

.cart-container-show-cart .product-cart-name {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}

.cart-container-show-cart  .product-cart-quantity  {
    margin-right: 50px;
    font-size: 14px;
}
.cart-container-show-cart .counter-cart button{
    font-size: 14px;
    font-weight: 500;

}
.product-cart-quantity-popup{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    margin: 2%;
    width: 40%;
}
.counter-cart-popup{
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: var(--orange-brule) !important;
}
.counter-cart-popup{
    width: 90px;
    height: 35px;
    font-size: 28px;
    font-weight: 600;
    display: flex;
    justify-content: center;
}
#quantity-cart-popup{
    width: 70px;
    font-size: 20px;
    font-weight: 600;
}
.content-trash-cart{
    height: 100%;
    display: flex;
    align-items: center;

}

.icon-delete-cart{
    font-size: 18px;
    margin-right: 15px;
}
#container-product-cart-popup{
    display: flex;
    align-items: center ;
    justify-content: center;
}
/* Responsive */

@media screen and (max-width : 768px){
    .show-cart{
        background-color: var(--blanc);
        width: 100%;
        left: 0;
    }

    .form-cart-uknow{
        width: 90%;
        margin: 5% 0;
    }

    .cart-container-show-cart ul li{
        height: 5rem;
        /* border: none; */
    }

    .cart-container{
        flex-direction: column-reverse;
        align-items: center;
        margin: 0;
        padding: 0;
    }
    .cart-container-left {
        position: relative;
        top: 10px;
        width: 98%;
        /* min-height: 650px; */
    }
    .cart-container-right {
        width: 98%;
    }
    .details-content-cart{
        flex-direction: column;
    }
    .cart-container-left .details-product-cart{
        font-size: 10px;
    }
    .details-product-cart .product-total-price{
        display: none;
    }
    .details-product-cart .product-cart-quantity{
        margin-right: 30px;
    }
    #img-cart{
        width: 50px;
        height: 35px;
    }
    #qty-cart{
        display: none;
    }
    .product-cart-quantity-popup{
        position: relative;
        right: 20px;

    }

    .content-cart-amphy{
        align-items: center;
        text-align: center;
    }
    .length-cart{
        text-align: center;
    }
}