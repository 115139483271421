.container-login {
    display: flex;
    /* flex-direction: column-reverse; */
    height: auto;

}

.left-section-login,
.right-section-login {
    width: 100%;
    padding: 30px;
    height: auto;
}

.left-section-login {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.logo-login img{
    width: 20%;
}

.container-login h1 {
    font-size: 1.8rem;
    font-weight: bold;
    /* margin-bottom: 10px; */

}
.title-signup{
    /* margin-top: 100px; */
}
.container-login p {
    margin-bottom: 20px;
    color: #666;

}

.container-login form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;

}

.container-login label {
    /* margin-bottom: 5px; */
    width: 100%;

}

.container-login input {
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 18px;
    width: 100%;
    background-color: var(--gris-clair);
}
#btn-resetPassword{
    width: 94%;
}
#para-login{
    color: var(--blanc);
}

.parent-form-input .reset-link {
    text-align: start;
    font-weight: 600;
    text-decoration: none;
    color: var(--bleu-ciel);
    display: block;
    margin-bottom: 15px;
}

.password-input {
    position: relative;
}

.password-input .icon-eye {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.btn,
.btn-google,
.btn-facebook {
    display: flex;
    
    justify-content: center;
    padding: 12px;
    font-size: 1rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    /* margin-bottom: 15px; */
}

.btn {
    background-color: var(--bleu-ciel);
    color: white;
    text-decoration: none;
    /* margin-top: 30px; */
}

.btn-google,
.btn-facebook {
    width: 400px !important;
    background-color: #f5f5f5;
    color: #333;
    border: 1px solid #ccc;
}
.google-login button{
    width: 230px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}


.signup {
    margin-top: 20px;
    text-align: center;
}

.signup a {
    color: #1E90FF;
    text-decoration: none;
}

.para1 {
    text-align: center;
    margin: 20px 0;
}

.right-section-login {
    background-color: #00aaff;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.image-container img {
    max-width: 60%;
    height: auto;
}

.container-login h2 {
    margin-top: 20px;
    font-size: 1.8rem;
    font-weight: bold;
}

.right-section-login p {
    margin-top: 10px;
    color: #e0e0e0;
}


/*  Style du fond du popup */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/*  Style de la boîte de dialogue */
.popup-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    width: 90%;
    max-width: 400px; /* Taille maximale sur grand écran */
}

/*  Style du champ input */
.popup input {
    width: 90%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
}

/*  Style du bouton */
.popup button {
    background-color: var(--bleu-ciel);
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    width: 96%;
}

.popup button:hover {
    background-color: var(--bleu-ciel);
}

/*  Responsive pour petits écrans */
@media screen and (max-width: 600px) {
    .popup-content {
        width: 90%;
        padding: 10px;
    }

    .popup input {
        width: 85%;
        font-size: 14px;
    }

    .popup button {
        width: 90%;
        font-size: 14px;
        padding: 8px 12px;
    }
}


@media screen and (max-width: 768px) {

    .container-login {
        flex-direction: column-reverse ;
        height: auto;
        margin: 0 !important;
        padding: 0 !important;
        width: 100% !important;        
    }

    .right-section-login{
        height: 48vh;
    }
    .left-section-login,
    .right-section-login {
        width: 95%;

        padding: 3% !important;
        margin: 0% !important;
    }
    .logo-login img{
        width: 30%;
    }
    

    .container-login h1 {
        font-size: 2rem;
    }

    .btn,
    .btn-google,
    .btn-facebook {
        font-size: 1.1rem;
        padding: 15px;
    }

    .container-login h2 {
        font-size: 2rem;
    }
}

@media screen and (min-width: 1024px) {
    .container-login form {
        max-width: 400px;
    }
}

.signIn {
    display: flex;
    justify-content: center;
    margin: 10px;
}

.otp-container {
    display: flex;
    flex-direction: column-reverse;
    min-height: 100vh;

    .otp-left,
    .otp-right {
        width: 100%;
        padding: 30px;
    }

    .otp-left {
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
            font-size: 1.8rem;
            font-weight: bold;
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 20px;
            color: #666;
        }

        form {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 400px;

            .otp-input-wrapper {
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;

                .otp-box {
                    padding: 12px;
                    border: 1px solid #ccc;
                    border-radius: 8px;
                    font-size: 1.5rem;
                    width: 50px;
                    text-align: center;
                }
            }

            .otp-submit-btn {
                background-color: var(--bleu-ciel);
                text-decoration: none;
                color: white;
                padding: 15px;
                font-size: 1.2rem;
                border: none;
                border-radius: 8px;
                cursor: pointer;
                width: 100%;
                margin-top: 20px;
            }
        }

        .otp-resend-text {
            text-align: center;
            margin-top: 20px;
        }
    }

    .otp-right {
        background-color: #00aaff;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        p {
            margin-top: 10px;
            color: #e0e0e0;
        }

        img {
            max-width: 100%;
            height: auto;
        }
    }

    h2 {
        margin-top: 20px;
        font-size: 1.8rem;
        font-weight: bold;
    }

    .otp-input-wrapper .otp-box:focus {
        border-color: #1E90FF;
        outline: none;
        box-shadow: 0 0 0 2px rgba(30, 144, 255, 0.3);
    }

}

@media screen and (min-width: 768px) {
    .otp-container {
        flex-direction: row;
    }

    .otp-left,
    .otp-right {
        width: 50%;
        padding: 50px;
    }

    .container-login h1 {
        font-size: 2rem;
    }

    .otp-submit-btn {
        font-size: 1.1rem;
    }

    .container-login h2 {
        font-size: 2rem;
    }
}